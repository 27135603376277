interface ImpersonateUserFormProps {
  userId: any;
}

const _new = ({userId}: ImpersonateUserFormProps) => ({
  userId,
});

const toForm = ({userId}: ImpersonateUserFormProps) => ({
  userId,
});

const toMutation = ({userId}: ImpersonateUserFormProps) => ({
  userId,
});

const ImpersonateUserForm = {
  new: _new,
  toForm,
  toMutation,
};

export default ImpersonateUserForm;
