// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled, Tooltip} from '@supermove/components';
import {useCallback, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Container = Styled.View`
  z-index: ${({index}: any) => 100 - index};
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: ${({isMedium}: any) => (isMedium ? '24px' : '20px')};
`;

const LabelText = Styled.Text`
  ${({vars}: any) => (vars.isMedium ? Typography.Body2 : Typography.Body)}
  color: ${colors.gray.primary};
`;

const TooltipText = Styled.Text`
  ${({vars}: any) => (vars.isMedium ? Typography.Body2 : Typography.Body)}
  color: ${colors.white};
`;

const ActionButton = Styled.ButtonV2`
`;

const ActionText = Styled.Text`
  ${({vars}: any) => (vars.isMedium ? Typography.Label1 : Typography.Label)}
  color: ${colors.blue.interactive};
`;

const TextInput = Styled.TextInput`
  ${({vars}: any) => (vars.isMedium ? Typography.Body2 : Typography.Body)}
  height: ${({vars}: any) => (vars.isMedium ? 48 : 36)}px;
`;

const CaptionText = Styled.Text`
  ${({vars}: any) => (vars.isMedium ? Typography.Mobile.Body : Typography.Desktop.Body)}
  color: ${({vars}: any) => (vars.hasErrors ? colors.red.warning : colors.gray.tertiary)};
`;

// flex: 1 allows the label to overflow into an ellipsis with numberOfLines
const LabelContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const RequiredLabel = Styled.Text`
  ${({vars}: any) => (vars.isMedium ? Typography.Body2 : Typography.Body)}
  color: ${colors.gray.tertiary};
  font-style: italic;
  padding-right: 2px;
`;

const ClearButtonContainer = Styled.ButtonV2`
  z-index: 100;
  position: absolute;
  top: ${({isMedium}: any) => (isMedium ? 46 : 34)}px;
  right: 14px;
`;

const FIELD_SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

const getBackgroundColor = ({isDisabled, required, backgroundColor, isReadOnly}: any) => {
  if (isDisabled || isReadOnly) {
    return colors.gray.border;
  }
  // 'required' is a deprecated property. We continue to support it because many older
  // field inputs were designed to not support the updated 'isRequired' styling. Namely,
  // the '–– required' text by the label may break the spacing of the header. 'isRequired'
  // will maintain the default background color and show required indicators with the label.
  if (required) {
    return colors.alpha(colors.yellow.hover, 0.1);
  }
  if (backgroundColor) {
    return backgroundColor;
  }
  return colors.white;
};

const getBorderColor = ({hasErrors}: any) => {
  if (hasErrors) {
    return colors.red.warning;
  }
  return colors.gray.tertiary;
};

const getDisplaySize = ({isResponsive, responsive, size}: any) => {
  if (isResponsive && !responsive.desktop) {
    return FIELD_SIZE.MEDIUM;
  }
  return size;
};

const getIsMedium = (size: any) => {
  return size === FIELD_SIZE.MEDIUM;
};

const Spacer = ({size, isResponsive}: any) => {
  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  return <Space height={getIsMedium(displaySize) ? 8 : 4} />;
};

const TooltipWrapper = ({tooltip, placement, isMedium, children}: any) => {
  if (tooltip) {
    return (
      <Tooltip
        placement={placement}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
        overlay={() => <TooltipText vars={{isMedium}}>{tooltip}</TooltipText>}
      >
        {children}
      </Tooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const Label = ({
  size,
  style,
  isRequired,
  isTruncatedRequiredLabel,
  isTruncated,
  isResponsive,
  tooltip,
  children,
}: any) => {
  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  const isMedium = getIsMedium(displaySize);
  // By default the label does not get truncated. This allows longer labels to
  // overflow. When we do truncate the label, we pass the label content into the
  // tooltip so that the user can see any truncated content by hovering over the label.
  return (
    <Row style={isTruncated && {flex: 1}}>
      {isRequired && (
        <LabelText style={{color: colors.red.warning}} vars={{isMedium}}>
          *
        </LabelText>
      )}
      <Row style={isTruncated && {maxWidth: '100%'}}>
        <TooltipWrapper tooltip={isTruncated && children} placement={'topLeft'} isMedium={isMedium}>
          <Row style={isTruncated && {flex: 1}}>
            <LabelText numberOfLines={1} style={style} vars={{isMedium}}>
              {children}
            </LabelText>
          </Row>
        </TooltipWrapper>
      </Row>
      {tooltip && (
        <React.Fragment>
          <Space width={8} />
          <TooltipWrapper tooltip={tooltip} placement={'top'} isMedium={isMedium}>
            <Row>
              <Icon source={Icon.InfoCircle} size={13} color={colors.gray.secondary} />
            </Row>
          </TooltipWrapper>
        </React.Fragment>
      )}
      {isRequired && !isTruncatedRequiredLabel && (
        <RequiredLabel vars={{isMedium}}>{` –– required`}</RequiredLabel>
      )}
    </Row>
  );
};

const Input = ({innerRef, size, isResponsive, style, ...props}: any) => {
  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  return (
    <TextInput
      ref={innerRef}
      style={style}
      vars={{isMedium: getIsMedium(displaySize)}}
      {...props}
    />
  );
};

const Caption = ({size, style, hasErrors, isResponsive, children}: any) => {
  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  return (
    <CaptionText style={style} vars={{isMedium: getIsMedium(displaySize), hasErrors}}>
      {children}
    </CaptionText>
  );
};

const Action = ({size, style, isResponsive, children}: any) => {
  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  return (
    <ActionText style={style} vars={{isMedium: getIsMedium(displaySize)}}>
      {children}
    </ActionText>
  );
};

const Header = ({
  label,
  action,
  actionText,
  handleAction,
  size,
  LabelIconComponent,
  isRequired,
  isTruncatedRequiredLabel,
  isTruncatedLabel,
  tooltip,
}: any) => {
  return (
    <React.Fragment>
      <HeaderContainer isMedium={getIsMedium(size)}>
        {!_.isNil(label) && (
          <LabelContainer>
            <Label
              size={size}
              isRequired={isRequired}
              isTruncated={isTruncatedLabel}
              isTruncatedRequiredLabel={isTruncatedRequiredLabel}
              tooltip={tooltip}
            >
              {label}
            </Label>
            {!!LabelIconComponent && (
              <React.Fragment>
                <Space width={6} />
                <LabelIconComponent />
              </React.Fragment>
            )}
          </LabelContainer>
        )}
        {action}
        {!!actionText && !!handleAction && (
          <ActionButton onPress={handleAction}>
            <Action size={size}>{actionText}</Action>
          </ActionButton>
        )}
      </HeaderContainer>
      <Spacer size={size} />
    </React.Fragment>
  );
};

const Footer = ({caption, hasErrors, errorMessage, hasWarning, warningMessage, size}: any) => {
  const iconSize = getIsMedium(size) ? 14 : 12;
  const iconStyle = {alignSelf: 'baseline', marginTop: 4};
  if (hasErrors) {
    return (
      <React.Fragment>
        <Spacer size={size} />
        <Row>
          <Icon source={Icon.Ban} color={colors.red.warning} size={iconSize} style={iconStyle} />
          <Space width={8} />
          <Caption size={size} style={{color: colors.red.warning}}>
            {errorMessage}
          </Caption>
        </Row>
      </React.Fragment>
    );
  }
  if (hasWarning) {
    return (
      <React.Fragment>
        <Spacer size={size} />
        <Row>
          <Icon
            source={Icon.ExclamationTriangle}
            color={colors.orange.status}
            size={iconSize}
            style={iconStyle}
          />
          <Space width={8} />
          <Caption size={size} style={{color: colors.orange.status}}>
            {warningMessage}
          </Caption>
        </Row>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Spacer size={size} />
      <Caption size={size}>{caption}</Caption>
    </React.Fragment>
  );
};

interface ClearButtonProps {
  setFieldValue: (field: string, value: any) => void;
  name: string;
  size: string;
  isResponsive: boolean;
  style?: React.CSSProperties;
}

const ClearButton = ({setFieldValue, name, size, isResponsive, style}: ClearButtonProps) => {
  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  const isMedium = getIsMedium(displaySize);
  return (
    <ClearButtonContainer onPress={() => setFieldValue(name, '')} isMedium={isMedium} style={style}>
      <Icon source={Icon.Xmark} size={isMedium ? 18 : 16} color={colors.gray.secondary} />
    </ClearButtonContainer>
  );
};

type FieldInputType = React.FC<FieldInputProps> & {
  LabelText: React.FC<any>;
  CaptionText: React.FC<any>;
  ActionText: React.FC<any>;
  TextInput: React.FC<any>;
  SIZE: typeof FIELD_SIZE;
  Spacer: React.FC<any>;
  Footer: React.FC<any>;
  Memoized: React.FC<FieldInputProps>;
};

export interface FieldInputProps {
  action?: React.ReactNode;
  actionText?: string;
  caption?: string;
  component?: React.ElementType;
  errors?: any;
  handleAction?: (event: any) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange?: (event: any) => void;
  index?: number;
  input?: any;
  label?: string;
  LabelIconComponent?: React.ElementType;
  name?: string;
  errorName?: string;
  size?: string;
  style?: any;
  touched?: any;
  values?: any;
  value?: string | number;
  hasWarning?: boolean;
  warningMessage?: string;
  showErrorMessage?: boolean;
  handleCommandEnter?: () => void;
  handleEnter?: (event: any) => void;
  handleShiftEnter?: () => void;
  containerRef?: any;
  setFieldValue?: (field: string, value: any) => void;
  customSetFieldValue?: () => void;
  isClearable?: boolean;
  clearBtnStyle?: React.CSSProperties;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isTruncatedRequiredLabel?: boolean;
  isTruncatedLabel?: boolean;
  isResponsive?: boolean;
  tooltip?: string;
}

const FieldInput: FieldInputType = ({
  action,
  actionText,
  caption,
  component: InputComponent = Input,
  errors,
  handleAction,
  handleBlur,
  handleChange,
  index = 0,
  input = {},
  label,
  LabelIconComponent,
  name = '',
  errorName,
  size = FIELD_SIZE.SMALL,
  style,
  touched,
  values,

  // Value directly sets the value property on the input component.
  // Typically the value is set by pulling the key with name out of the values prop
  value,

  hasWarning,
  warningMessage,
  showErrorMessage = true,
  handleCommandEnter = () => {},
  handleEnter = () => {},
  handleShiftEnter = () => {},
  containerRef,
  setFieldValue = () => {},
  customSetFieldValue,
  isClearable = false,
  clearBtnStyle,

  // isReadOnly differs from a disabled input in that it still receives click
  // and drag events.
  isReadOnly = false,
  isRequired = false,

  // Truncating the "-- required" label is useful for smaller inputs and mobile views
  isTruncatedRequiredLabel = false,

  isTruncatedLabel = false,
  isResponsive = false,
  tooltip,
}: FieldInputProps) => {
  const inputValue = value || _.get(values, name);
  const isDisabled = _.get(input, 'disabled') || _.get(input, 'isDisabled');
  // Note(dan) When passing in a DropdownInput, do not use this isClearable
  // prop. DropdownInput has a custom clear button, and you should enable
  // it with 'input: {isClearable: true}`
  const hasClearButton = !!inputValue && !isDisabled && isClearable;

  const hasHeader = !_.isNil(label) || action || (actionText && handleAction);

  const isTouched = !!_.get(touched, errorName || name);
  const errorMessage = _.get(errors, errorName || name);
  const hasErrors = isTouched && errorMessage;
  const hasFooter = caption || hasErrors || hasWarning;

  const responsive = useResponsive();
  const displaySize = getDisplaySize({isResponsive, responsive, size});
  const isMedium = getIsMedium(displaySize);
  const fieldHeight = isMedium ? 48 : 36;
  const fontSize = isMedium ? 16 : 14;
  const inputBaseStyle = {
    height: fieldHeight,
    minHeight: fieldHeight,
    fontSize,
    color: colors.gray.primary,
    backgroundColor: getBackgroundColor({...input, isDisabled, isReadOnly}),
    borderColor: getBorderColor({hasErrors}),
    ...(hasClearButton ? {paddingRight: 34} : {}),
  };
  const inputIncomingStyle = _.get(input, 'style', {});

  const setValue = customSetFieldValue || setFieldValue;
  const onChangeText = useCallback((text: any) => setValue(name, text), [name, setValue]);

  return (
    <Container index={index} style={style} ref={containerRef}>
      {hasHeader && (
        <Header
          label={label}
          action={action}
          actionText={actionText}
          handleAction={handleAction}
          size={displaySize}
          LabelIconComponent={LabelIconComponent}
          isRequired={isRequired}
          isTruncatedLabel={isTruncatedLabel}
          isTruncatedRequiredLabel={isTruncatedRequiredLabel}
          tooltip={tooltip}
        />
      )}
      <InputComponent
        name={name}
        value={inputValue}
        label={label}
        onChange={handleChange}
        onChangeText={onChangeText}
        onBlur={handleBlur}
        {...input}
        pointerEvents={isReadOnly ? 'none' : 'auto'}
        readOnly={isReadOnly}
        placeholder={isDisabled ? null : input.placeholder}
        style={{...inputBaseStyle, ...inputIncomingStyle}}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
            handleCommandEnter();
          } else if (event.shiftKey && event.key === 'Enter') {
            handleShiftEnter();
          } else if (event.key === 'Enter') {
            handleEnter(event);
          }
        }}
      />
      {hasFooter && showErrorMessage && (
        <Footer
          caption={caption}
          hasErrors={hasErrors}
          errorMessage={errorMessage}
          hasWarning={hasWarning}
          warningMessage={warningMessage}
          size={displaySize}
        />
      )}
      {hasClearButton && (
        <ClearButton
          setFieldValue={setValue}
          name={name}
          size={displaySize}
          style={clearBtnStyle}
          isResponsive={isResponsive}
        />
      )}
    </Container>
  );
};

FieldInput.LabelText = Label;
FieldInput.CaptionText = Caption;
FieldInput.ActionText = Action;
FieldInput.TextInput = Input;
FieldInput.SIZE = FIELD_SIZE;
FieldInput.Spacer = Spacer;
FieldInput.Footer = Footer;

// Formik offers an input called <FastField />, which we do not
// have access to since we only use the Formik hook and not the
// Formik components. However, under the hood, <FastField /> is
// utilizing shouldComponentUpdate, and we are able to emulate
// that behavior with React.memo. We should default to using our
// standard FieldInput, and use the memoized version only when
// pages are noticeably lagging in production.
// reference: https://formik.org/docs/api/fastfield
const areEqual = (prevProps: any, nextProps: any) => {
  const prevValue = _.get(prevProps.values, prevProps.name);
  const nextValue = _.get(nextProps.values, nextProps.name);
  const prevError = _.get(prevProps.errors, prevProps.name);
  const nextError = _.get(nextProps.errors, nextProps.name);
  const prevDisabled = prevProps.input?.disabled || prevProps.input?.isDisabled;
  const nextDisabled = nextProps.input?.disabled || nextProps.input?.isDisabled;
  return prevValue === nextValue && prevError === nextError && prevDisabled === nextDisabled;
};

FieldInput.Memoized = React.memo(FieldInput, areEqual);

export default FieldInput;
