// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Modal as BaseModal, IconSource, ScrollView, Styled} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import DangoButton from '@shared/design/components/Button';
import {GetRefType} from '@shared/utilities/typescript';

// App

const WIDTH = {
  LARGE: 1000,
  MEDIUM: 800,
  SMALL: 400,
} as const;

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-vertical: ${({mobile}: any) => (mobile ? '0px' : '40px')};
`;

const ContentContainer = Styled.View`
  background-color: ${colors.white};
  overflow: hidden;
  ${(props: any) => props.isResponsive && 'max-width: 100%; max-height: 100%;'}
  ${(props: any) =>
    props.isResponsive && props.mobile
      ? 'width: 100%; height: 100%;'
      : `width: ${props.width}px; border-radius: 4px;`}
`;

const BodyContainer = Styled.View`
  background-color: ${colors.gray.background};
  padding: 24px;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const HeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const BoldText = Styled.Text`
  ${Typography.Label1}
`;

const Text = Styled.Text`
  ${Typography.Body2}
`;

const Block = Styled.View<{index?: number}>`
  z-index: ${({index = 0}) => 100 - index};
  padding: 24px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const BlockHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const ButtonText = Styled.Text<{color: string}>`
  ${Typography.Label2}
  color: ${({color}) => color};
`;

const HeaderContainer = Styled.View<{responsive?: ResponsiveType | null}>`
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}) => (responsive?.mobile ? '16px' : '24px')};
  padding-vertical: 16px;
`;

const FooterContainer = Styled.View<{responsive?: ResponsiveType | null}>`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}) => (responsive?.mobile ? '16px' : '24px')};
  padding-vertical: 16px;
`;

const View = Styled.View`
`;

const PreventPropagationContainer = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: object;
}) => {
  return (
    <View
      onStartShouldSetResponder={() => true}
      onTouchEnd={(e: any) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

const Button = ({
  color,
  onPress,
  iconLeft,
  iconSize,
  isSubmitting,
  isDisabled,
  isWidthOfContainer,
  isResponsive,
  style,
  children,
}: {
  color?: string;
  onPress: () => void;
  iconLeft?: IconSource;
  iconSize?: number;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  isWidthOfContainer?: boolean;
  isResponsive?: boolean;
  style?: object;
  children: React.ReactNode | string;
}) => {
  return (
    <DangoButton
      color={color}
      textColor={color ? undefined : colors.blue.interactive}
      onPress={onPress}
      iconLeft={iconLeft}
      iconSize={iconSize}
      isDisabled={isDisabled}
      isSubmitting={isSubmitting}
      isWidthOfContainer={isWidthOfContainer}
      isResponsive={isResponsive}
      style={{minWidth: 112, width: isWidthOfContainer ? '100%' : 'auto', ...style}}
      text={children}
    />
  );
};

const Header = ({
  children,
  style,
  isResponsive,
}: {
  children: React.ReactNode;
  style?: object;
  isResponsive?: boolean;
}) => {
  const responsive = useResponsive();
  return (
    <HeaderContainer style={style} responsive={isResponsive ? responsive : null}>
      {children}
    </HeaderContainer>
  );
};

const Body = ({
  children,
  style,
  scrollRef,
  isScrollable,
}: {
  children: React.ReactNode;
  style?: object;
  scrollRef?: GetRefType<typeof ScrollView>;
  isScrollable?: boolean;
}) => {
  const responsive = useResponsive();

  if (isScrollable) {
    return (
      <ScrollView
        style={{
          backgroundColor: colors.gray.background,
          padding: 24,
          ...style,
        }}
        ref={scrollRef}
        showsVerticalScrollIndicator={responsive.desktop}
      >
        {children}
      </ScrollView>
    );
  }
  return <BodyContainer style={style}>{children}</BodyContainer>;
};

const Footer = ({
  children,
  style,
  isResponsive,
}: {
  children: React.ReactNode;
  style?: object;
  isResponsive?: boolean;
}) => {
  const responsive = useResponsive();
  return (
    <FooterContainer style={style} responsive={isResponsive ? responsive : null}>
      {children}
    </FooterContainer>
  );
};

const Modal = ({
  isOpen,
  handlePressOutside,
  children,
  width,
  isResponsive,
  style,
  screenContainerStyle,
}: {
  isOpen: boolean;
  handlePressOutside: () => void;
  children: React.ReactNode;
  width?: number;
  isResponsive?: boolean;
  style?: object | null;
  screenContainerStyle?: object;
}) => {
  const responsive = useResponsive();

  return (
    // BaseModal.Content requires an onClose prop. However, the only functionality for the function
    // is to handle when pressing outside of the modal. Here we have a default prop that we always
    // pass in which effectively does nothing when pressing outside of the modal. We can then
    // optionally pass in any logic with handlePressOutside to take place when pressing outside of
    // the modal, whether it be to simply close the modal or more.
    <BaseModal.Content isOpen={isOpen} onClose={handlePressOutside}>
      <ScreenContainer {...responsive} pointerEvents={'box-none'} style={screenContainerStyle}>
        {/*
          When restricting the height of the modal, pass in style={{flex: 1}}. Otherwise, the height
          of the modal will be dynamic to the content, and if there is a lot of vertical content it
          will expand off the view.
        */}
        <ContentContainer width={width} {...responsive} isResponsive={isResponsive} style={style}>
          {children}
        </ContentContainer>
      </ScreenContainer>
    </BaseModal.Content>
  );
};

const Responsive = (props: any) => <Modal {...props} isResponsive />;

Modal.HeaderText = HeaderText;
Modal.HeaderDescription = HeaderDescription;
Modal.BoldText = BoldText;
Modal.Text = Text;
Modal.Button = Button;
Modal.ButtonText = ButtonText;
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Block = Block;
Modal.BlockHeader = BlockHeader;
Modal.Responsive = Responsive;
Modal.WIDTH = WIDTH;
Modal.PreventPropagationContainer = PreventPropagationContainer;

// --------------------------------------------------
// Props
// --------------------------------------------------
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handlePressOutside: PropTypes.func,
  width: PropTypes.number,
  isResponsive: PropTypes.bool,
  style: PropTypes.object,
  screenContainerStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  handlePressOutside: () => {},
  width: null,
  isResponsive: false,
  style: null,
  screenContainerStyle: null,
};

Button.propTypes = {
  color: PropTypes.string,
  onPress: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isResponsive: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  style: PropTypes.object,
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  iconSize: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  color: null,
  onPress: () => {},
  isResponsive: false,
  isSubmitting: false,
  isWidthOfContainer: false,
  isDisabled: false,
  style: {},
  iconLeft: null,
  iconSize: undefined,
};

export default Modal;
