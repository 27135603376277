// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';

const QuaternaryButtonText = ({
  isDisabled,
  color,
  style,
  children,
}: {
  isDisabled?: boolean;
  color: string;
  style: object;
  children: React.ReactNode;
}) => {
  return (
    <Button.Text color={isDisabled ? colors.gray.border : color} style={style}>
      {children}
    </Button.Text>
  );
};

const QuaternaryButton = ({
  children,
  isDisabled,
  isSubmitting,
  textColor,
  style,
  ...props
}: {
  children?: React.ReactNode;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  textColor?: string;
  style?: object | null;
}) => {
  return (
    <Button
      {...props}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      textColor={isDisabled ? colors.gray.border : textColor}
      style={{
        backgroundColor: 'transparent',
        ...style,
      }}
    >
      {children}
    </Button>
  );
};

QuaternaryButton.Text = QuaternaryButtonText;

// --------------------------------------------------
// Props
// --------------------------------------------------
QuaternaryButton.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  textColor: PropTypes.string,
  onPress: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  isResponsive: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

QuaternaryButton.defaultProps = {
  color: 'transparent',
  width: null,
  textColor: colors.gray.secondary,
  onPress: () => {},
  isDisabled: false,
  style: {},
};

QuaternaryButtonText.propTypes = {
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
};

QuaternaryButtonText.defaultProps = {
  color: colors.gray.secondary,
  isDisabled: false,
  style: null,
};

export default QuaternaryButton;
