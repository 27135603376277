/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, IconSource, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {
  ScrollViewType,
  useEffect,
  useHover,
  usePopover,
  useResponsive,
  useScrollView,
  useState,
} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const ARROWS_WIDTH = 40;

const Container = Styled.View<{
  isLarge?: boolean;
}>`
  border: 1px solid ${colors.gray.tertiary};
  border-radius: 4px;
  padding: 4px;
  flexShrink: 1;
  height: ${({isLarge}) => (isLarge ? '48px' : '36px')};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ActionsIconContainer = Styled.View`
  padding-horizontal: 6px;
`;

const ContextButton = Styled.ButtonV2<{
  isSelected?: boolean;
  isFullWidth?: boolean;
}>`
  ${({isFullWidth}) => (isFullWidth ? 'flex: 1' : '')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : colors.white)};
  border-radius: 2px;
  overflow: hidden;
  padding-vertical: 2px;
`;

const BadgeContainer = Styled.View<{
  color: string;
}>`
  background-color: ${({color}) => colors.getBackgroundColor(color)};
  padding-horizontal: 8px;
  padding-vertical: 1px;
  justify-content: center;
  border-radius: 4px;
`;

const BadgeLabel = Styled.Text<{
  color: string;
}>`
  ${Typography.MicroLabel}
  color: ${({color}) => color};
`;

const Label = Styled.Text<{
  vars: {isLarge?: boolean};
  color: string;
}>`
  ${({vars}) => (vars.isLarge ? Typography.Mobile.Label : Typography.Label)}
  color: ${({color}) => color};
`;

const getColor = ({isSelected, isHovered}: {isSelected?: boolean; isHovered?: boolean}) => {
  return isSelected
    ? colors.blue.interactive
    : isHovered
      ? colors.blue.hover
      : colors.gray.secondary;
};

const handleScrollPrev = ({
  scrollViewHandler,
  positions,
  currentPosition,
}: {
  scrollViewHandler: ScrollViewType;
  positions: number[];
  currentPosition: number;
}) => {
  const prevPosition = positions.reduce((result, position) =>
    Math.ceil(position) < Math.floor(currentPosition) ? position : result,
  );
  scrollViewHandler.handleScrollTo({x: prevPosition, animated: true});
};

const handleScrollNext = ({
  scrollViewHandler,
  positions,
  currentPosition,
  layoutWidth,
}: {
  scrollViewHandler: ScrollViewType;
  positions: number[];
  currentPosition: number;
  layoutWidth: number;
}) => {
  const endPosition = currentPosition + layoutWidth;
  const nextEndPosition = positions.reduce(
    (result, position) =>
      result || (Math.floor(position) > Math.ceil(endPosition) ? position : result),
  );
  if (nextEndPosition) {
    scrollViewHandler.handleScrollTo({x: nextEndPosition - layoutWidth, animated: true});
  } else {
    scrollViewHandler.handleScrollToEnd({animated: true});
  }
};

const Badge = ({label, color}: {label: string; color: string}) => {
  return (
    <BadgeContainer color={color}>
      <BadgeLabel color={color}>{label}</BadgeLabel>
    </BadgeContainer>
  );
};

const ActionsButton = ({actions, actionMenuWidth}: {actions: any[]; actionMenuWidth?: number}) => {
  const actionsPopover = usePopover();
  const {ref, isHovered} = useHover();

  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <TertiaryButton onPress={actionsPopover.handleToggle} style={{paddingHorizontal: 8}}>
          <ActionsIconContainer ref={ref}>
            <Icon
              source={Icon.EllipsisV}
              color={isHovered || actionsPopover.isOpen ? colors.blue.hover : colors.gray.secondary}
              size={12}
            />
          </ActionsIconContainer>
        </TertiaryButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={actions}
          width={actionMenuWidth}
        />
      </Popover>
    </React.Fragment>
  );
};

const ContextOption = ({
  isSelected,
  isDisabled,
  isFullWidth,
  isLarge,
  onPress,
  leftBadgeLabel,
  leftBadgeColor,
  iconSource,
  label,
  rightBadgeLabel,
  rightBadgeColor,
  actions,
  actionMenuWidth,
  setPositions,
  index,
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLarge?: boolean;
  onPress: () => void;
  leftBadgeLabel?: string;
  leftBadgeColor: any;
  iconSource?: IconSource | null;
  label?: string | null;
  rightBadgeLabel?: string;
  rightBadgeColor: any;
  actions?: any[];
  actionMenuWidth?: number;
  setPositions: React.Dispatch<React.SetStateAction<number[]>>;
  index: number;
}) => {
  const {ref, isHovered} = useHover();
  return (
    <ContextButton
      isSelected={isSelected}
      isFullWidth={isFullWidth}
      disabled={isDisabled}
      onPress={onPress}
      onLayout={({nativeEvent}: {nativeEvent: {layout: {x: number}}}) => {
        setPositions((positions) => {
          const updatedPositions = [...positions];
          updatedPositions[index] = nativeEvent.layout.x;
          return updatedPositions;
        });
      }}
    >
      <Space width={12} />
      <Row ref={ref}>
        {leftBadgeLabel && (
          <React.Fragment>
            <Badge label={leftBadgeLabel} color={leftBadgeColor} />
            {!!label && <Space width={8} />}
          </React.Fragment>
        )}
        {iconSource && (
          <React.Fragment>
            <Icon size={16} color={getColor({isSelected, isHovered})} source={iconSource} />
            {!!label && <Space width={8} />}
          </React.Fragment>
        )}
        {!!label && (
          <Label vars={{isLarge}} color={getColor({isSelected, isHovered})}>
            {label}
          </Label>
        )}
        {rightBadgeLabel && (
          <React.Fragment>
            {!!label && <Space width={8} />}
            <Badge label={rightBadgeLabel} color={rightBadgeColor} />
          </React.Fragment>
        )}
      </Row>
      {actions ? (
        <React.Fragment>
          <ActionsButton actions={actions} actionMenuWidth={actionMenuWidth} />
          <Space width={4} />
        </React.Fragment>
      ) : (
        <Space width={12} />
      )}
    </ContextButton>
  );
};

export interface ContextDefinitionKind {
  leftBadgeLabel?: string;
  leftBadgeColor?: any;
  iconSource?: IconSource | null;
  label?: string | null;
  rightBadgeLabel?: string;
  rightBadgeColor?: any;
  isSelected?: boolean;
  isDisabled?: boolean;
  onPress: () => void;
  actions?: any[];
}

const ContextSwitcher = ({
  contextDefinitions,
  scrollView,
  isFullWidth,
  actionMenuWidth,
  containerStyle = {},
}: {
  contextDefinitions: ContextDefinitionKind[];
  scrollView?: ScrollViewType;
  isFullWidth?: boolean;
  actionMenuWidth?: number;
  containerStyle?: object;
}) => {
  const [isCompletedInitialScroll, setIsCompletedInitialScroll] = useState(false);
  const [isMaxWidth, setIsMaxWidth] = useState(false);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const [positions, setPositions] = useState<number[]>([]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [contentSize, setContentSize] = useState(0);
  const responsive = useResponsive();
  const isAtStart = currentPosition === 0;
  const defaultScrollView = useScrollView();
  const scrollViewHandler = scrollView || defaultScrollView;
  const isLarge = !responsive.desktop;

  useEffect(() => {
    if (!isCompletedInitialScroll && positions.length === contextDefinitions.length) {
      const selectedIndex = _.findIndex(contextDefinitions, ['isSelected', true]);
      scrollViewHandler.handleScrollTo({x: positions[selectedIndex], animated: true});
      setIsCompletedInitialScroll(true);
    }
  }, [positions.length]);

  useEffect(() => {
    if (layoutWidth && contentSize) {
      if (isMaxWidth && layoutWidth + ARROWS_WIDTH >= contentSize) {
        setIsMaxWidth(false);
      }
      if (!isMaxWidth && layoutWidth < contentSize) {
        setIsMaxWidth(true);
      }
    }
  }, [isMaxWidth, layoutWidth, contentSize]);

  return (
    <Row style={{flexShrink: 1, flexGrow: isFullWidth ? 1 : 0, backgroundColor: colors.white}}>
      {isMaxWidth && (
        <TertiaryButton
          onPress={() => handleScrollPrev({scrollViewHandler, positions, currentPosition})}
          onLongPress={() => scrollViewHandler.handleScrollTo({x: 0, animated: true})}
          isDisabled={isAtStart}
        >
          <Icon
            source={Icon.ChevronLeft}
            size={isLarge ? 14 : 12}
            color={isAtStart ? colors.gray.tertiary : colors.blue.interactive}
          />
          <Space width={12} />
        </TertiaryButton>
      )}
      <Container
        isLarge={isLarge}
        style={{...(isFullWidth ? {flex: 1} : null), ...containerStyle}}
        onLayout={({nativeEvent}: any) => setLayoutWidth(nativeEvent.layout.width)}
      >
        <ScrollView
          ref={scrollViewHandler.ref}
          horizontal
          showsHorizontalScrollIndicator={false}
          onContentSizeChange={(size: number) => setContentSize(size)}
          onScroll={({nativeEvent}: any) => {
            const {layoutMeasurement, contentOffset, contentSize} = nativeEvent;
            const isScrollAtEnd =
              Math.ceil(layoutMeasurement.width) + Math.ceil(contentOffset.x) >= contentSize.width;
            setIsAtEnd(isScrollAtEnd);
            setCurrentPosition(nativeEvent.contentOffset.x);
          }}
          contentContainerStyle={isFullWidth ? {flex: 1, justifyContent: 'space-evenly'} : null}
        >
          {contextDefinitions.map(
            (
              {
                leftBadgeLabel,
                leftBadgeColor,
                iconSource,
                label,
                rightBadgeLabel,
                rightBadgeColor,
                isSelected,
                isDisabled,
                onPress,
                actions,
              },
              index: number,
            ) => (
              <ContextOption
                key={index}
                isSelected={isSelected}
                isDisabled={isDisabled}
                isFullWidth={isFullWidth}
                isLarge={isLarge}
                onPress={onPress}
                leftBadgeLabel={leftBadgeLabel}
                leftBadgeColor={leftBadgeColor}
                iconSource={iconSource}
                label={label}
                rightBadgeLabel={rightBadgeLabel}
                rightBadgeColor={rightBadgeColor}
                actions={actions}
                actionMenuWidth={actionMenuWidth}
                setPositions={setPositions}
                index={index}
              />
            ),
          )}
        </ScrollView>
      </Container>
      {isMaxWidth && (
        <TertiaryButton
          onPress={() =>
            handleScrollNext({scrollViewHandler, positions, currentPosition, layoutWidth})
          }
          onLongPress={() => scrollViewHandler.handleScrollToEnd({animated: true})}
          isDisabled={isAtEnd}
        >
          <Space width={12} />
          <Icon
            source={Icon.ChevronRight}
            size={isLarge ? 14 : 12}
            color={isAtEnd ? colors.gray.tertiary : colors.blue.interactive}
          />
        </TertiaryButton>
      )}
    </Row>
  );
};

export default ContextSwitcher;
