// Libraries
import React, {Key} from 'react';

// Supermove
import {Icon, IconSource, Space, Styled, Tooltip} from '@supermove/components';
import {useState, useEffect, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const SIDE_SPACE_AMOUNT = 24;

const TreeNavigationContainer = Styled.View<{width?: number}>`
  width: ${({width}) => (width ? `${width}px` : undefined)};
`;

const NodeButton = Styled.ButtonV2<{color: string}>`
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  padding-right: ${SIDE_SPACE_AMOUNT}px;
  background-color: ${({color}) => color};
`;

const NodeButtonText = Styled.Text<{color: string}>`
  ${Typography.Micro}
  color: ${({color}) => color};
`;

const SelectedIndicator = Styled.View`
  position: absolute;
  left: 0px;
  height: 100%;
  width: 2px;
  background-color: ${colors.blue.interactive};
`;

const HoverIndicator = Styled.View`
  position: absolute;
  left: 0px;
  height: 100%;
  width: 2px;
  background-color: ${colors.blue.hover};
`;

const ExpandButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

const TooltipContentContainer = Styled.View`
`;

const IconContainer = Styled.View`
`;

const FlashIndicator = Styled.View<{color?: string}>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color || colors.red.warning};
`;

type BaseNodeType<T extends ValuesType> = {
  // Ideally these types would be valueKey: keyof T and value: T[keyof T], but we have a navigation type used in our app that is a more complicated object
  // If we get to a point where we can remove that, we can remove most of the "as" casts below
  valueKey: string;
  value: T[keyof T] | unknown;
  label?: string;
  tooltip?: string;
  icon?: IconSource;
  isDisabled?: boolean;
  isFlashing?: boolean;
  flashColor?: string;
};
type LeafNodeType<T extends ValuesType> = BaseNodeType<T> & {
  items?: never;
};
type ParentNodeType<T extends ValuesType> = BaseNodeType<T> & {
  items: NodeType<T>[];
};
export type NodeType<T extends ValuesType> = LeafNodeType<T> | ParentNodeType<T>;
type ValuesType = string | Record<Key, any>; // Ideally this would just be Record<Key, any>, but we have a nav type that has an optional string value

const getNodeButtonBackgroundColor = ({
  isSelected,
  isHovered,
  isDisabled,
}: {
  isSelected?: boolean;
  isHovered?: boolean;
  isDisabled?: boolean;
}) => {
  if (isSelected) {
    return colors.blue.accent;
  }
  if (isDisabled) {
    return colors.white;
  }
  if (isHovered) {
    return colors.blue.accent;
  }
  return colors.white;
};

const getNodeButtonTextColor = ({
  isSelected,
  isHovered,
  isDisabled,
}: {
  isSelected?: boolean;
  isHovered?: boolean;
  isDisabled?: boolean;
}) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isHovered) {
    return colors.blue.hover;
  }
  return colors.gray.secondary;
};

const getNodeButtonIconColor = ({
  isSelected,
  isHovered,
  isDisabled,
}: {
  isSelected?: boolean;
  isHovered?: boolean;
  isDisabled?: boolean;
}) => {
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isHovered && !isSelected) {
    return colors.blue.hover;
  }
  return colors.blue.interactive;
};

const handlePressNode = <T extends ValuesType>({
  node,
  parentNodes,
  handleSetValues,
}: {
  node: NodeType<T>;
  parentNodes: ParentNodeType<T>[];
  handleSetValues: (values: T) => void;
}) => {
  if (!node.items) {
    const values = {} as T;
    [...parentNodes, node].forEach((branchNode) => {
      values[branchNode.valueKey as keyof T] = branchNode.value as T[keyof T];
    });
    return handleSetValues(values);
  }

  // Behave as if the first child node was pressed
  handlePressNode({node: node.items[0], parentNodes: [...parentNodes, node], handleSetValues});
};

const IndentSpace = ({count}: {count: number}) => {
  return <Space width={count * SIDE_SPACE_AMOUNT} />;
};

const StatusBar = ({showSelected, showHovered}: {showSelected: boolean; showHovered: boolean}) => {
  if (showSelected) {
    return <SelectedIndicator />;
  }
  if (showHovered) {
    return <HoverIndicator />;
  }
  return null;
};

const NodeWrapper = <T extends ValuesType>({
  node,
  children,
  tooltipPlacement,
}: {
  node: NodeType<T>;
  children: React.ReactNode;
  tooltipPlacement?: string;
}) => {
  if (node.tooltip) {
    return (
      <Tooltip
        overlay={<NodeButtonText color={colors.white}>{node.tooltip}</NodeButtonText>}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
        placement={tooltipPlacement}
        overlayStyle={{opacity: 100}}
      >
        <TooltipContentContainer>{children}</TooltipContentContainer>
      </Tooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const ChildNodes = <T extends ValuesType>({
  node,
  parentNodes,
  handleSetValues,
  values,
  spacing,
}: {
  node: NodeType<T>;
  parentNodes: ParentNodeType<T>[];
  handleSetValues: (values: T) => void;
  values: T;
  spacing?: number;
}) => {
  return (
    <React.Fragment>
      {node.items?.map((item: any, index: any) => {
        return (
          <React.Fragment key={index}>
            {spacing && <Space height={spacing} />}
            <Node
              node={item}
              parentNodes={[...parentNodes, node]}
              handleSetValues={handleSetValues}
              values={values}
              spacing={spacing}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const ParentNode = <T extends ValuesType>({
  node,
  parentNodes,
  handleSetValues,
  values,
  spacing,
  isCentered,
  isDisabled,
  getIsParentNodeSelected,
}: {
  node: ParentNodeType<T>;
  parentNodes: ParentNodeType<T>[];
  handleSetValues: (values: T) => void;
  values: T;
  spacing?: number;
  isCentered?: boolean;
  isDisabled?: boolean;
  getIsParentNodeSelected?: (node: NodeType<T>) => boolean;
}) => {
  const {ref, isHovered} = useHover();
  const disabled = isDisabled || node.isDisabled;
  const isSelected = getIsParentNodeSelected
    ? getIsParentNodeSelected(node)
    : values[node.valueKey as keyof T] === node.value;
  const textColor = getNodeButtonTextColor({isHovered, isDisabled: disabled});
  const [isExpanded, setIsExpanded] = useState(!disabled);

  useEffect(() => {
    if (isSelected) {
      setIsExpanded(true);
    }
  }, [isSelected, setIsExpanded]);

  return (
    <React.Fragment>
      <NodeButton
        ref={ref}
        onPress={() => {
          handlePressNode({node, parentNodes, handleSetValues});
          setIsExpanded(true);
        }}
        color={getNodeButtonBackgroundColor({
          isSelected: isSelected && !isExpanded,
          isHovered,
          isDisabled: disabled,
        })}
        style={isCentered && {paddingRight: 0, justifyContent: 'center'}}
        disabled={disabled}
      >
        <Space width={18} />
        {!isCentered && <IndentSpace count={parentNodes.length} />}
        <ExpandButton onPress={() => setIsExpanded(!isExpanded)} disabled={disabled}>
          <Icon
            source={isExpanded ? Icon.ChevronDown : Icon.ChevronRight}
            size={10}
            color={textColor}
          />
        </ExpandButton>
        <Space width={2} />
        <NodeButtonText color={textColor}>{node.label}</NodeButtonText>
        {!disabled && (
          <StatusBar showSelected={isSelected && !isExpanded} showHovered={isHovered} />
        )}
      </NodeButton>
      {isExpanded && (
        <ChildNodes
          node={node}
          parentNodes={parentNodes}
          handleSetValues={handleSetValues}
          values={values}
          spacing={spacing}
        />
      )}
    </React.Fragment>
  );
};

const LeafNode = <T extends ValuesType>({
  node,
  parentNodes,
  handleSetValues,
  values,
  isCentered,
  isDisabled,
}: {
  node: LeafNodeType<T>;
  parentNodes: ParentNodeType<T>[];
  handleSetValues: (values: T) => void;
  values: T;
  isCentered?: boolean;
  isDisabled?: boolean;
}) => {
  const {ref, isHovered} = useHover();
  const disabled = isDisabled || node.isDisabled;
  const isSelected = values[node.valueKey as keyof T] === node.value;

  return (
    <NodeButton
      ref={ref}
      onPress={() => handlePressNode({node, parentNodes, handleSetValues})}
      color={getNodeButtonBackgroundColor({isSelected, isHovered, isDisabled: disabled})}
      disabled={disabled}
      style={isCentered && {paddingRight: 0, justifyContent: 'center'}}
    >
      {!isCentered && <IndentSpace count={parentNodes.length + 1} />}
      {node.icon && (
        <IconContainer>
          <Icon
            source={node.icon}
            color={getNodeButtonIconColor({isSelected, isHovered, isDisabled: disabled})}
            size={16}
          />
          {node.isFlashing && !node.label && (
            <FlashIndicator
              color={node.flashColor}
              style={{position: 'absolute', top: -5, right: -5}}
            />
          )}
        </IconContainer>
      )}
      {node.label && (
        <React.Fragment>
          <NodeButtonText
            color={getNodeButtonTextColor({isSelected, isHovered, isDisabled: disabled})}
          >
            {node.label}
          </NodeButtonText>
          <Space flex={1} />
          {node.isFlashing && <FlashIndicator color={node.flashColor} style={{marginRight: -12}} />}
        </React.Fragment>
      )}
      {!disabled && <StatusBar showSelected={isSelected} showHovered={isHovered} />}
    </NodeButton>
  );
};

const Node = <T extends ValuesType>({
  node,
  parentNodes = [],
  handleSetValues,
  values,
  spacing,
  isCentered,
  isDisabled,
  tooltipPlacement,
  getIsParentNodeSelected,
}: {
  node: NodeType<T>;
  parentNodes?: ParentNodeType<T>[];
  handleSetValues: (values: T) => void;
  values: T;
  spacing?: number;
  isCentered?: boolean;
  isDisabled?: boolean;
  tooltipPlacement?: string;
  getIsParentNodeSelected?: (node: NodeType<T>) => boolean;
}) => {
  return (
    <NodeWrapper node={node} tooltipPlacement={tooltipPlacement}>
      {node.items ? (
        <ParentNode
          node={node}
          parentNodes={parentNodes}
          handleSetValues={handleSetValues}
          values={values}
          spacing={spacing}
          isCentered={isCentered}
          isDisabled={isDisabled}
          getIsParentNodeSelected={getIsParentNodeSelected}
        />
      ) : (
        <LeafNode
          node={node}
          parentNodes={parentNodes}
          handleSetValues={handleSetValues}
          values={values}
          isCentered={isCentered}
          isDisabled={isDisabled}
        />
      )}
    </NodeWrapper>
  );
};

const TreeNavigation = <T extends ValuesType, N>({
  navigationItems,
  width,
  values,
  handleSetValues,
  spacing,
  isCentered,
  isDisabled,
  tooltipPlacement,
  getIsParentNodeSelected,
}: {
  navigationItems: NodeType<T>[];
  width?: number;
  values: T;
  handleSetValues: (values: T) => void;
  spacing?: number;
  isCentered?: boolean;
  isDisabled?: boolean;
  tooltipPlacement?: string;
  getIsParentNodeSelected?: (node: NodeType<T>) => boolean;
}) => {
  return (
    <TreeNavigationContainer width={width}>
      {navigationItems.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {spacing && index > 0 && <Space height={spacing} />}
            <Node
              node={item}
              values={values}
              handleSetValues={handleSetValues}
              spacing={spacing}
              isCentered={isCentered}
              isDisabled={isDisabled}
              tooltipPlacement={tooltipPlacement}
              getIsParentNodeSelected={getIsParentNodeSelected}
            />
          </React.Fragment>
        );
      })}
    </TreeNavigationContainer>
  );
};

export default TreeNavigation;
