import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {LeadStageModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getActiveLeadStages = withFragment(
  (leadStages: LeadStageModel[]) => {
    return leadStages.filter((stage) => !stage.archivedAt);
  },
  gql`
    fragment LeadStage_getActiveLeadStages on LeadStage {
      id
      archivedAt
    }
  `,
);

const getArchivedLeadStages = withFragment(
  (leadStages: LeadStageModel[]) => {
    return leadStages.filter((stage) => !!stage.archivedAt);
  },
  gql`
    fragment LeadStage_getArchivedLeadStages on LeadStage {
      id
      archivedAt
    }
  `,
);

const COLOR_SELECTOR_OPTIONS = {
  Purple: '#9B51E0',
  Blue: '#34ACE0',
  Green: '#27AE60',
  Yellow: '#F1C40F',
  Orange: '#F2A12C',
  Red: '#E74C3C',
  Pink: '#E84393',
  Grey: '#616161',
  Black: '#000000',
};

const getColorNameFromValue = (value?: string) => {
  const colorName = Object.keys(COLOR_SELECTOR_OPTIONS).find(
    (key) => COLOR_SELECTOR_OPTIONS[key as keyof typeof COLOR_SELECTOR_OPTIONS] === value,
  );
  return _.startCase(_.toLower(colorName || ''));
};

const getColorSelectorDropdownOptions = () =>
  Object.keys(COLOR_SELECTOR_OPTIONS).map((key, index) => ({
    label: key,
    value: COLOR_SELECTOR_OPTIONS[key as keyof typeof COLOR_SELECTOR_OPTIONS],
    index,
  }));

const LeadStage = {
  getActiveLeadStages,
  getArchivedLeadStages,
  getColorSelectorDropdownOptions,
  getColorNameFromValue,
};

export default LeadStage;
