// Libraries
import _ from 'lodash';

export interface Step {
  kind: string;
  title: string;
  description: string;
}

const USER_INFO = 'USER_INFO';
const REVIEW = 'REVIEW';

const EMPTY_STEP: Step = {
  kind: '',
  title: '',
  description: '',
};

const STEPS: Step[] = [
  {
    kind: USER_INFO,
    title: 'Basic Information',
    description:
      'Enter the team member’s contact information and details. This can be updated later.',
  },
  {
    kind: REVIEW,
    title: 'Review',
    description: 'Before submitting, make sure this information is correct.',
  },
];

const getStep = (kind: string): Step => {
  return _.find(STEPS, (step) => step.kind === kind) || EMPTY_STEP;
};

const getPreviousStep = (kind: string): Step => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index <= 0 ? undefined : STEPS[index - 1];
  return step || EMPTY_STEP;
};

const getNextStep = (kind: string): Step => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index < 0 || index >= STEPS.length - 1 ? undefined : STEPS[index + 1];
  return step || EMPTY_STEP;
};

const InviteOfficeAppUserStep = {
  USER_INFO,
  REVIEW,

  STEPS,
  getStep,
  getPreviousStep,
  getNextStep,

  // These functions are for current compatibility with useProgress
  getPreviousStepKind: (kind: string) => InviteOfficeAppUserStep.getPreviousStep(kind).kind,
  getNextStepKind: (kind: string) => InviteOfficeAppUserStep.getNextStep(kind).kind,
};

export default InviteOfficeAppUserStep;
