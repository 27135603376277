// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Typography, colors} from '@supermove/styles';

const ButtonGroupContainer = Styled.View`
  width: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ButtonGroupButton = Styled.ButtonV2<{
  isSelected?: boolean;
  leftBorder?: boolean;
  rightBorder?: boolean;
  disabled?: boolean;
}>`
  align-items: center;
  justify-content: center;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : colors.white)};
  border-width: 1px;
  ${({leftBorder}) => leftBorder && 'border-top-left-radius: 4px; border-bottom-left-radius: 4px;'}
  ${({rightBorder}) =>
    rightBorder && 'border-top-right-radius: 4px; border-bottom-right-radius: 4px;'}
  ${({isSelected}) => (isSelected ? 'margin-left: -1px;' : 'margin-right: -1px;')}
  border-color: ${({isSelected}) => (isSelected ? colors.blue.interactive : colors.gray.border)};
  padding: 8px 16px;
`;

const ButtonText = Styled.Text`
  ${Typography.Label}
  color: ${({vars}: {vars: {isSelected?: boolean}}) => (vars.isSelected ? colors.blue.interactive : colors.gray.secondary)};
`;

const ButtonGroup = ({
  options,
  selectedOptionValues,
  showCounts,
  handleOptionPress,
  containerStyle,
}: {
  options: Array<{
    label: string;
    value: string;
    count?: number | string;
    disabled?: boolean;
  }>;
  selectedOptionValues: Array<string>;
  handleOptionPress: (value: string) => void;
  showCounts?: boolean;
  containerStyle?: object;
}) => {
  return (
    <ButtonGroupContainer style={containerStyle}>
      <Row>
        {options.map(({label, value, count, disabled}, index: number) => {
          const isSelected = _.includes(selectedOptionValues, value);
          return (
            <ButtonGroupButton
              key={index}
              isSelected={isSelected}
              leftBorder={index === 0}
              rightBorder={index === options.length - 1}
              onPress={() => handleOptionPress(value)}
              disabled={disabled}
            >
              <ButtonText vars={{isSelected}}>
                {label}
                {showCounts && (
                  <React.Fragment>
                    <Space width={5} />
                    {count}
                  </React.Fragment>
                )}
              </ButtonText>
            </ButtonGroupButton>
          );
        })}
      </Row>
    </ButtonGroupContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      count: PropTypes.string,
    }),
  ).isRequired,
  selectedOptionValues: PropTypes.array.isRequired,
  handleOptionPress: PropTypes.func,
  showCounts: PropTypes.bool,
  containerStyle: PropTypes.object,
};

ButtonGroup.defaultProps = {
  handleOptionPress: () => {},
  showCounts: false,
  containerStyle: {},
};

export default ButtonGroup;
