// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const BetaBadgeContainer = Styled.View<{
  isSmall?: boolean;
}>`
  height: ${({isSmall}) => (isSmall ? '16px' : '20px')};
  padding-horizontal: ${({isSmall}) => (isSmall ? '6px' : '12px')};
  background-color: ${colors.blue.accent};
  border-radius: ${({isSmall}) => (isSmall ? '8px' : '10px')};
  justify-content: center;
`;

const BetaBadgeText = Styled.Text`
  ${Typography.Label}
  color: ${colors.blue.interactive};
`;

const BetaBadgeTextSmall = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.blue.interactive};
`;

const BetaBadge = ({isSmall}: {isSmall?: boolean}) => {
  return (
    <BetaBadgeContainer isSmall={isSmall}>
      {isSmall ? (
        <BetaBadgeTextSmall>BETA</BetaBadgeTextSmall>
      ) : (
        <BetaBadgeText>BETA</BetaBadgeText>
      )}
    </BetaBadgeContainer>
  );
};

export default BetaBadge;
