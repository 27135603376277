// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ImpersonateUserForm from '@shared/modules/User/forms/ImpersonateUserForm';

const useImpersonateUserMutation = ({impersonateUserForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      impersonateUserForm: ImpersonateUserForm.toForm(impersonateUserForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useImpersonateUserMutation.mutation,
    variables: {
      impersonateUserForm: ImpersonateUserForm.toMutation(form.values.impersonateUserForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useImpersonateUserMutation.mutation = gql`
  mutation useImpersonateUserMutation($impersonateUserForm: ImpersonateUserForm) {
    response: impersonateUser(impersonateUserForm: $impersonateUserForm) {
      ${gql.errors}
      viewer {
        id
        role
      }
    }
  }
`;

export default useImpersonateUserMutation;
