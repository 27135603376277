// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {TimesheetBlock} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Hint from '@shared/design/components/Hint';
import EditTimesheetBillableBlockModal from '@shared/modules/Timesheet/components/EditTimesheetBillableBlockModal';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';
import UserRole from '@shared/modules/User/enums/UserRole';

const TimesheetBlocksListContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
`;

const ListItemContainer = Styled.View`
  flex-direction: row;
  padding-vertical: 8px;
  padding-horizontal: 24px;
  align-items: center;
`;

const ListItemColumn = Styled.View`
  align-items: flex-end;
`;

const ListItemText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const MinutesText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const TimesheetBlocksListItem = ({
  job,
  timesheetBillableEntry,
  timesheetBlock,
  nextTimesheetBlock,
  refetch,
  isFirst,
  isLast,
  onUpdate,
  responsive,
  viewerRole,
}: any) => {
  const editTimesheetBlockModal = useModal({name: 'Edit Timesheet Block Modal'});
  const {kind} = timesheetBlock;
  const totalMinutes = TimesheetBlock.getMinutes(timesheetBlock);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return (
    <React.Fragment>
      <ListItemContainer>
        <Icon source={Icon.Circle} size={8} color={TimesheetBlockKind.getColor(kind)} />
        <Space width={8} />
        <ListItemText numberOfLines={1} responsive={responsive}>
          {TimesheetBlockKind.getDisplay(kind)}
        </ListItemText>
        <Space style={{flex: 1, minWidth: 64}} />
        <ListItemColumn>
          <ListItemText responsive={responsive}>
            {TimesheetBlock.getDisplayRange(timesheetBlock)}
          </ListItemText>
          <MinutesText responsive={responsive}>{`${hours}H ${minutes}M`}</MinutesText>
        </ListItemColumn>
        <Space width={64} />
        {/* @ts-expect-error TS(2786): 'Hint' cannot be used as a JSX component. */}
        <Hint message={job.isFinal ? UserRole.getJobActionDisabledTooltip(viewerRole) : ''}>
          <TertiaryButton
            iconLeft={Icon.Pen}
            text={responsive.mobile ? null : 'Edit'}
            isResponsive
            onPress={editTimesheetBlockModal.handleOpen}
            isDisabled={job.isFinal}
            isHitSlop
          />
        </Hint>
      </ListItemContainer>
      <EditTimesheetBillableBlockModal
        key={editTimesheetBlockModal.key}
        job={job}
        timesheetBillableEntry={timesheetBillableEntry}
        timesheetBlock={timesheetBlock}
        nextTimesheetBlock={nextTimesheetBlock}
        isOpen={editTimesheetBlockModal.isOpen}
        handleClose={editTimesheetBlockModal.handleClose}
        refetch={refetch}
        isFirstTimesheetBlock={isFirst}
        isLastTimesheetBlock={isLast}
        onUpdate={onUpdate}
      />
    </React.Fragment>
  );
};

const TimesheetBillableEntryTimesheetBlocks = ({
  job,
  timesheetBillableEntry,
  refetch,
  onUpdate,
  responsive,
  viewerRole,
}: any) => {
  return (
    <TimesheetBlocksListContainer>
      {timesheetBillableEntry.timesheetBlocks.map((timesheetBlock: any, index: any) => {
        const isFirst = index === 0;
        return (
          <React.Fragment key={timesheetBlock.id}>
            {!isFirst && <Line />}
            <TimesheetBlocksListItem
              job={job}
              timesheetBillableEntry={timesheetBillableEntry}
              timesheetBlock={timesheetBlock}
              nextTimesheetBlock={timesheetBillableEntry.timesheetBlocks[index + 1]}
              refetch={refetch}
              isFirst={isFirst}
              isLast={index === timesheetBillableEntry.timesheetBlocks.length - 1}
              onUpdate={onUpdate}
              responsive={responsive}
              viewerRole={viewerRole}
            />
          </React.Fragment>
        );
      })}
    </TimesheetBlocksListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimesheetBillableEntryTimesheetBlocks.fragment = gql`
  ${TimesheetBlock.getDisplayRange.fragment}
  ${TimesheetBlock.getMinutes.fragment}
  ${EditTimesheetBillableBlockModal.fragment}

  fragment TimesheetBillableEntryTimesheetBlocks on Job {
    id
    timesheetBillableEntries {
      id
      timesheetBlocks {
        id
        kind
        ...TimesheetBlock_getDisplayRange
        ...TimesheetBlock_getMinutes
      }
    }
    ...EditTimesheetBillableBlockModal
  }
`;

export default TimesheetBillableEntryTimesheetBlocks;
