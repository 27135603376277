import {ExecutionResult, MutationResult, MutationFunctionOptions} from 'react-apollo';

// Supermove
import {gql, useMutation} from '@supermove/graphql';
import {MutationResponse} from '@supermove/hooks/src/forms/types';

type EnablePayEngineGatewayResponse = {
  payengineMerchant: {
    id: number;
    merchantId: string;
  };
};

type GraphQLResponse = MutationResponse<EnablePayEngineGatewayResponse>;

type EnablePayEngineGatewayForm = {
  form: {
    organizationId: number;
    gatewayUuid: string;
  };
};

type Result = {
  handleSubmit: (
    options?: MutationFunctionOptions<GraphQLResponse, EnablePayEngineGatewayForm>,
  ) => Promise<ExecutionResult<GraphQLResponse>>;
  mutationResult: MutationResult<GraphQLResponse>;
};

const useEnablePayEngineGatewayMutation = ({
  organizationId,
  gatewayUuid,
}: Partial<EnablePayEngineGatewayForm['form']>): Result => {
  const [handleSubmit, mutationResult] = useMutation(useEnablePayEngineGatewayMutation.mutation, {
    variables: {
      form: {
        organizationId: organizationId as number,
        gatewayUuid: gatewayUuid as string,
      },
    },
  });

  return {
    handleSubmit,
    mutationResult,
  };
};

useEnablePayEngineGatewayMutation.mutation = gql`
  mutation EnablePayEngineGatewayMutation(
    $form: EnablePayEngineGatewayForm!
  ) {
    response: enablePayEngineGateway(
      form: $form,
    ) {
      ${gql.errors}
      payengineMerchant {
        id
        merchantId
      }
    }
  }
`;

export default useEnablePayEngineGatewayMutation;
