// Libraries
import React from 'react';

// Supermove
import {Space, Icon, IconSource} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel, {ActionPanelProps} from '@shared/design/components/Panel/ActionPanel';

const EditButton = ({
  isEditing,
  handleEdit,
  isDisabled,
}: {
  isEditing: boolean;
  handleEdit: () => void;
  isDisabled: boolean;
}) => {
  if (isEditing) {
    return null;
  }

  return (
    <TertiaryButton
      isResponsive
      text={'Edit'}
      iconLeft={Icon.Pen}
      onPress={handleEdit}
      isDisabled={isDisabled}
    />
  );
};

export interface EditPanelProps extends ActionPanelProps {
  EditBodyComponent: React.ComponentType<any>;
  EditButtonComponent?: React.ComponentType<any>;
  editButtonComponentProps?: object;
  handleSave: () => void;
  saveButtonText?: string;
  SaveButtonIconComponent?: IconSource | null;
  handleCancel?: () => void;
  cancelButtonText?: string;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  editBodyComponentProps?: object;
  isEditButtonVisible?: boolean;
  isEditing?: boolean;
  handleEdit?: () => void;
  handleClose?: () => void;
}

const EditPanel = ({
  width = 784,
  bodyComponentProps = {},
  editBodyComponentProps = {},
  BodyComponent,
  EditBodyComponent,
  EditButtonComponent = EditButton,
  editButtonComponentProps = {},
  handleSave,
  saveButtonText = 'Save',
  SaveButtonIconComponent = null,
  handleCancel = () => {},
  cancelButtonText = 'Cancel',
  isDisabled = false,
  isSubmitting = false,
  isEditing = false,
  handleEdit = () => {},
  handleClose = () => {},
  isEditButtonVisible = true,
  ...actionPanelProps
}: EditPanelProps) => {
  const responsive = useResponsive();
  return (
    <ActionPanel
      {...actionPanelProps}
      width={width}
      bodyComponentProps={isEditing ? editBodyComponentProps : bodyComponentProps}
      BodyComponent={isEditing ? EditBodyComponent : BodyComponent}
      ActionButtonComponent={() => {
        if (!isEditButtonVisible) {
          return null;
        }

        if (!responsive.desktop) {
          return (
            <React.Fragment>
              {!isEditing ? (
                <TertiaryButton
                  isResponsive
                  text={'Edit'}
                  iconLeft={Icon.Pen}
                  onPress={handleEdit}
                  isDisabled={isDisabled}
                />
              ) : (
                <TertiaryButton
                  isResponsive
                  text={'Cancel'}
                  onPress={() => {
                    handleClose();
                    handleCancel();
                  }}
                />
              )}
            </React.Fragment>
          );
        }
        return (
          <EditButtonComponent
            isEditing={isEditing}
            handleEdit={handleEdit}
            isDisabled={isDisabled}
            {...editButtonComponentProps}
          />
        );
      }}
      actionButtonComponentProps={editButtonComponentProps}
      FooterComponent={
        isEditing
          ? () =>
              !responsive.desktop ? (
                <Button
                  isResponsive
                  onPress={handleSave}
                  isDisabled={isDisabled}
                  isSubmitting={isSubmitting}
                  text={saveButtonText}
                  iconLeft={SaveButtonIconComponent}
                  isWidthOfContainer
                  style={{flex: 1, marginHorizontal: 16, marginBottom: 16}}
                />
              ) : (
                <React.Fragment>
                  <Button
                    color={colors.white}
                    textColor={colors.gray.secondary}
                    onPress={() => {
                      handleClose();
                      handleCancel();
                    }}
                    isDisabled={isSubmitting}
                    text={cancelButtonText}
                  />
                  <Space width={16} />
                  <Button
                    onPress={handleSave}
                    isDisabled={isDisabled}
                    isSubmitting={isSubmitting}
                    text={saveButtonText}
                    iconLeft={SaveButtonIconComponent}
                  />
                </React.Fragment>
              )
          : null
      }
    />
  );
};

EditPanel.Text = ActionPanel.Text;
EditPanel.LabelText = ActionPanel.LabelText;
EditPanel.HeaderText = ActionPanel.HeaderText;
EditPanel.EditButton = EditButton;

export default EditPanel;
