// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled, Tooltip} from '@supermove/components';
import {ResponsiveType, useHover, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import {
  DropdownActionType,
  SectionType,
} from '@shared/design/components/DropdownInput/components/DropdownSheet';
import SearchBar from '@shared/design/components/SearchBar';

const PopoverContainer = Styled.View<{width?: number; height?: number}>`
  width: ${({width}) => (width ? `${width}px` : null)};
  height: ${({height}) => (height ? `${height}px` : null)};
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  overflow: visible;
  min-width: 120px;
`;

const MenuLabelContainer = Styled.View`
  padding: 12px;
`;

const MenuLabelText = Styled.Text<{color: string}>`
  ${Typography.Responsive.Label};
  color: ${({color}) => color};
`;

const MenuItemTouchable = Styled.ButtonV2<{
  isHovered: boolean;
  disabled: boolean;
}>`
  background-color: ${({isHovered, disabled}) =>
    isHovered && !disabled ? colors.hover : colors.white};
  padding: 12px;
  width: 100%;
  flex-direction: row;
`;

const MenuItemTextAndDescriptionContainer = Styled.View`
  flex: 1;
`;

const MenuItemText = Styled.Text<{disabled?: boolean; color?: string}>`
  ${Typography.Responsive.Body};
  color: ${({disabled, color}) => (disabled ? colors.gray.tertiary : color || colors.gray.primary)};
`;

const MenuItemDescription = Styled.Text<{disabled?: boolean}>`
  ${Typography.Responsive.Micro};
  color: ${({disabled}) => (disabled ? colors.gray.tertiary : colors.gray.secondary)};
`;

const ScrollViewContainer = Styled.View<{maxHeight?: number}>`
  max-height: ${({maxHeight}) => `${maxHeight}px` || '100%'};
`;

const View = Styled.View`
`;

const TooltipText = Styled.Text`
  ${Typography.Body};
  color: ${colors.white};
`;

const ActionMenuDivider = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex: 1;
`;

const ActivityIndicator = Styled.Loading`
`;

const PaddingContainer = Styled.View`
  padding: 12px;
`;

const stringMatchesQuery = ({string, query}: {string: string; query: string}) => {
  return string.toLowerCase().trim().includes(query.toLowerCase().trim());
};

const getOptionMatchesSearch = ({action, query}: {action: DropdownActionType; query: string}) => {
  if (!query) {
    return true;
  }
  const searchString = `${'text' in action ? action.text : ''} ${'description' in action ? action.description : ''}`;
  return stringMatchesQuery({string: searchString, query});
};

const getFilteredActions = ({actions, query}: {actions: DropdownActionType[]; query: string}) => {
  const isSectioned = 'label' in actions[0] && 'actions' in actions[0];
  if (isSectioned) {
    const sections = actions as SectionType[];
    const filteredSectionActions = sections.map((section) => {
      if (stringMatchesQuery({string: section.label, query})) {
        return section;
      }
      return {
        ...section,
        actions: section.actions.filter((action) => getOptionMatchesSearch({action, query})),
      };
    });
    return filteredSectionActions.filter((section) => section.actions.length > 0);
  }
  return actions.filter((action) => getOptionMatchesSearch({action, query}));
};

const PreventPropagationContainer = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: object;
}) => {
  return (
    <View
      onStartShouldSetResponder={() => true}
      onTouchEnd={(e: any) => {
        e.stopPropagation();
      }}
      style={style}
    >
      {children}
    </View>
  );
};

const ActionMenuItemWrapper = ({
  tooltip,
  children,
}: {
  tooltip?: string;
  children: React.ReactNode;
}) => {
  if (tooltip) {
    return (
      <Tooltip
        overlay={<TooltipText>{tooltip}</TooltipText>}
        placement={'left'}
        mouseEnterDelay={0.0}
        mouseLeaveDelay={0.0}
      >
        {children}
      </Tooltip>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const ActionMenuLabel = ({
  color = colors.gray.primary,
  children,
}: {
  color?: string;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  return (
    <MenuLabelContainer>
      <MenuLabelText responsive={responsive} color={color}>
        {children}
      </MenuLabelText>
    </MenuLabelContainer>
  );
};

const ActionMenuSection = ({
  index,
  label,
  actions,
  handleClose,
  isTruncated,
  itemStyle,
  responsive,
}: {
  index: number;
  label: string;
  actions: any[];
  handleClose: () => void;
  isTruncated?: boolean;
  itemStyle?: object;
  responsive: ResponsiveType | null;
}) => {
  const isDisabledAllActions = actions.every((action) => action.isDisabled);
  const isHiddenAllActions = actions.every((action) => action.isHidden);

  if (isHiddenAllActions) {
    return null;
  }

  return (
    <React.Fragment>
      {index > 0 && (
        <React.Fragment>
          <Space height={12} />
          <ActionMenuDivider />
          <Space height={12} />
        </React.Fragment>
      )}
      <ActionMenuLabel color={isDisabledAllActions ? colors.gray.tertiary : undefined}>
        {label}
      </ActionMenuLabel>
      {actions.map(
        (
          {
            onPress,
            text,
            description,
            isDisabled,
            isHidden,
            isLoadedAction,
            isLoading,
            tooltip,
            color,
            actionHook,
          },
          index: number,
        ) => (
          <ActionMenuItem
            key={index}
            index={index}
            description={description}
            onPress={onPress}
            isTruncated={isTruncated}
            isDisabled={isDisabled}
            isHidden={isHidden}
            isLoadedAction={isLoadedAction}
            isLoading={isLoading}
            tooltip={tooltip}
            color={color}
            actionHook={actionHook}
            handleClose={handleClose}
            responsive={responsive}
            style={{paddingLeft: 24, ...itemStyle}}
          >
            {text}
          </ActionMenuItem>
        ),
      )}
    </React.Fragment>
  );
};

const ActionMenuItem = ({
  index,
  description,
  onPress,
  isTruncated,
  isDisabled,
  isHidden,
  isLoadedAction,
  isLoading,
  tooltip,
  color,
  actionHook,
  handleClose,
  style,
  children,
  label,
  responsive,
  actions,
}: {
  index: number;
  description?: string;
  onPress: () => void;
  isTruncated?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLoadedAction?: boolean;
  isLoading?: boolean;
  tooltip?: string;
  color?: string;
  actionHook: {
    hook: (hookArgument: Record<string, any>) => Record<string, any>;
    hookArgument: Record<string, any>;
    Component: React.FC<any>;
    componentProps: any;
    renderComponent?: (hook: Record<string, any>) => React.ReactNode;
  };
  handleClose: () => void;
  style?: object;
  children: React.ReactNode;
  label?: string;
  actions?: any[];
  responsive: ResponsiveType | null;
}) => {
  const {isHovered, handleToggleOff, ref} = useHover();
  const hook = actionHook.hook(actionHook.hookArgument);

  if (isHidden) {
    return null;
  }

  if (label && actions) {
    return (
      <ActionMenuSection
        index={index}
        label={label}
        actions={actions}
        handleClose={handleClose}
        isTruncated={isTruncated}
        itemStyle={style}
        responsive={responsive}
      />
    );
  }

  return (
    <ActionMenuItemWrapper tooltip={tooltip}>
      <MenuItemTouchable
        ref={ref}
        isHovered={isHovered}
        disabled={isDisabled || isLoading}
        activeOpacity={0.8}
        onPress={() => {
          onPress();
          hook.handleOpen && hook.handleOpen();
          handleToggleOff();
          !isLoadedAction && handleClose();
        }}
        style={style}
      >
        {isLoading && (
          <React.Fragment>
            <ActivityIndicator size={'small'} color={colors.blue.interactive} />
            <Space width={8} />
          </React.Fragment>
        )}
        <MenuItemTextAndDescriptionContainer>
          <MenuItemText
            numberOfLines={isTruncated ? 1 : 0}
            disabled={isDisabled || isLoading}
            color={color}
            responsive={responsive}
          >
            {children}
          </MenuItemText>
          {!!description && (
            <React.Fragment>
              <Space height={4} />
              <MenuItemDescription disabled={isDisabled || isLoading}>
                {description}
              </MenuItemDescription>
            </React.Fragment>
          )}
        </MenuItemTextAndDescriptionContainer>
        <PreventPropagationContainer>
          {/*
            TODO(dan) Update actionHook pattern to use actionHook.component. Supporting both
            actionHook.Component and actionHook.component for now while we transition.
          */}
          {actionHook.Component && (
            <actionHook.Component {...hook} {...actionHook.componentProps} />
          )}
          {actionHook.renderComponent && actionHook.renderComponent(hook)}
        </PreventPropagationContainer>
      </MenuItemTouchable>
    </ActionMenuItemWrapper>
  );
};

const ActionMenuContainer = ({
  width,
  height,
  maxHeight,
  children,
}: {
  width?: number;
  height?: number;
  maxHeight?: number;
  children: React.ReactNode;
}) => {
  return (
    <PopoverContainer width={width} height={height}>
      <ScrollView>
        <ScrollViewContainer maxHeight={maxHeight}>{children}</ScrollViewContainer>
      </ScrollView>
    </PopoverContainer>
  );
};

export interface ActionMenuProps {
  actions: DropdownActionType[];
  handleClose: () => void;
  width: number;
  height?: number;
  maxHeight: number;
  isTruncated?: boolean;
  itemStyle: object;
  isSearchable?: boolean;
  isResponsive?: boolean;
}

const ActionMenu = ({
  actions,
  handleClose,
  width,
  height,
  maxHeight,
  isTruncated,
  itemStyle,
  isSearchable,
  isResponsive,
}: ActionMenuProps) => {
  const responsive = useResponsive();
  const [query, setQuery] = useState('');
  const filteredActions = !query.trim() ? actions : getFilteredActions({actions, query});

  return (
    <ActionMenuContainer width={width} height={height} maxHeight={maxHeight}>
      {isSearchable ? (
        <PaddingContainer>
          <SearchBar isResponsive isClearable style={{flex: 1}} onChangeText={setQuery} autoFocus />
        </PaddingContainer>
      ) : (
        <Space height={12} />
      )}
      {!filteredActions.length ? (
        <PaddingContainer>
          <MenuItemText color={colors.gray.tertiary} disabled>
            No results found
          </MenuItemText>
        </PaddingContainer>
      ) : (
        <React.Fragment>
          {filteredActions.map(
            (
              {
                onPress,
                text,
                description,
                isDisabled,
                isHidden,
                isLoadedAction,
                isLoading,
                tooltip,
                color,
                actionHook,
                label,
                actions: sectionActions,
              }: any,
              index,
            ) => {
              return (
                <ActionMenuItem
                  key={index}
                  index={index}
                  description={description}
                  onPress={onPress}
                  isTruncated={isTruncated}
                  isDisabled={isDisabled}
                  isHidden={isHidden}
                  isLoadedAction={isLoadedAction}
                  isLoading={isLoading}
                  tooltip={tooltip}
                  color={color}
                  actionHook={actionHook}
                  handleClose={handleClose}
                  style={itemStyle}
                  label={label}
                  actions={sectionActions}
                  responsive={isResponsive ? responsive : null}
                >
                  {text}
                </ActionMenuItem>
              );
            },
          )}
        </React.Fragment>
      )}
      <Space height={12} />
    </ActionMenuContainer>
  );
};

ActionMenu.Container = ActionMenuContainer;
ActionMenu.Item = ActionMenuItem;
ActionMenu.Label = ActionMenuLabel;
ActionMenu.Divider = ActionMenuDivider;

// --------------------------------------------------
// Props
// --------------------------------------------------
ActionMenuItem.propTypes = {
  onPress: PropTypes.func,
  isTruncated: PropTypes.bool,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  actionHook: PropTypes.shape({
    hook: PropTypes.func,
    hookArgument: PropTypes.object,
    Component: PropTypes.func,
    componentProps: PropTypes.object,
  }),
  handleClose: PropTypes.func,
  label: PropTypes.string,
  actions: PropTypes.array,
  style: PropTypes.object,
};

ActionMenuItem.defaultProps = {
  onPress: () => null,
  isTruncated: false,
  isDisabled: false,
  tooltip: null,
  color: null,
  actionHook: {
    hook: () => ({}),
    hookArgument: {},
    Component: () => null,
    componentProps: {},
  },
  handleClose: () => {},
  label: null,
  actions: null,
  style: {},
};

ActionMenu.propTypes = {
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  width: PropTypes.number,
  maxHeight: PropTypes.number,
  itemStyle: PropTypes.object,
};

ActionMenu.defaultProps = {
  width: 120,
  maxHeight: null,
  itemStyle: {},
};

export default ActionMenu;
