// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

export type ProjectTypeRoomTypeFormType = {
  projectTypeRoomTypeId: number | null;
  projectTypeId: number;
  roomTypeId: number;
  name: string;
  isDeleted: boolean;
};

const _new = ({projectTypeId, roomTypeId, name = ''}: any) => ({
  projectTypeRoomTypeId: null,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted: false,
});

const edit = withFragment(
  (projectTypeRoomType) => ({
    projectTypeRoomTypeId: (projectTypeRoomType as any).id,
    projectTypeId: (projectTypeRoomType as any).projectTypeId,
    roomTypeId: (projectTypeRoomType as any).roomTypeId,
    name: (projectTypeRoomType as any).name,
    isDeleted: (projectTypeRoomType as any).isDeleted,
  }),
  gql`
    fragment ProjectTypeRoomTypeForm_edit on ProjectTypeRoomType {
      id
      projectTypeId
      roomTypeId
      name
      isDeleted
    }
  `,
);

const toForm = ({
  projectTypeRoomTypeId,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted,
}: ProjectTypeRoomTypeFormType) => ({
  projectTypeRoomTypeId,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted,
});

const toMutation = ({
  projectTypeRoomTypeId,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted,
}: ProjectTypeRoomTypeFormType) => ({
  projectTypeRoomTypeId,
  projectTypeId,
  roomTypeId,
  name,
  isDeleted,
});

const ProjectTypeRoomTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeRoomTypeForm;
