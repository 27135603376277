// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Json, withFragment, Validation} from '@supermove/utils';
import {ValidationErrors} from '@supermove/utils/src/Validation';

// App
import InviteOfficeAppUserStep from '@shared/modules/User/enums/InviteOfficeAppUserStep';

export interface InviteUserFormProps {
  userId: number | null;
  organizationId: number | null;
  names: any;
  email: string;
  phoneNumber: string;
  role: string;
  identifier: string;
  position: string;
  inviteToken: string;
  password: string;
  confirmPassword: string;
  isActive: boolean;
  status: string | null;
}

const _new = ({
  organizationId,
  inviteToken,
  email,
  role,
  position,
}: {
  organizationId?: number;
  inviteToken?: string;
  email?: string;
  role?: string;
  position?: string;
}) => ({
  organizationId,
  names: '["", ""]',
  email: email || '',
  phoneNumber: '',
  role: role || '',
  position: position || '',
  inviteToken: inviteToken || '',
  password: '',
  confirmPassword: '',
});

const edit = withFragment(
  (user: {
    id: number;
    organizationId: number;
    role: string;
    identifier: string;
    names: string;
    phoneNumber: string;
    email: string;
    position: string;
    isActive: boolean;
    status: string;
    inviteToken: string;
    password: string;
    confirmPassword: string;
  }) => ({
    userId: user.id,
    organizationId: user.organizationId,
    role: user.role,
    identifier: user.identifier,
    names: user.names,
    phoneNumber: user.phoneNumber,
    email: user.email,
    position: user.position,
    isActive: user.isActive,
    status: user.status,
    inviteToken: user.inviteToken,
    password: '',
    confirmPassword: '',
  }),
  gql`
    fragment InviteUserForm_edit on User {
      id
      organizationId
      role
      identifier
      names
      phoneNumber
      email
      position
      isActive
      status
      inviteToken
    }
  `,
);

const toForm = (inviteUserForm: InviteUserFormProps) => ({
  userId: inviteUserForm.userId,
  organizationId: inviteUserForm.organizationId,
  role: inviteUserForm.role,
  identifier: inviteUserForm.identifier,
  names: Json.toForm(inviteUserForm.names),
  phoneNumber: inviteUserForm.phoneNumber,
  position: inviteUserForm.position,
  email: inviteUserForm.email,
  isActive: inviteUserForm.isActive,
  status: inviteUserForm.status,
  inviteToken: inviteUserForm.inviteToken,
  password: inviteUserForm.password,
  confirmPassword: inviteUserForm.confirmPassword,
});

const toMutation = (inviteUserForm: InviteUserFormProps) => ({
  userId: inviteUserForm.userId,
  organizationId: inviteUserForm.organizationId,
  role: inviteUserForm.role,
  identifier: inviteUserForm.identifier,
  names: Json.toMutation(inviteUserForm.names.map((name: string) => name.trim())),
  phoneNumber: inviteUserForm.phoneNumber,
  email: inviteUserForm.email,
  position: inviteUserForm.position,
  isActive: inviteUserForm.isActive,
  status: inviteUserForm.status,
  inviteToken: inviteUserForm.inviteToken,
  password: inviteUserForm.password,
  confirmPassword: inviteUserForm.confirmPassword,
});

const validateUserInfo = ({form}: {form: any}): ValidationErrors => {
  const firstNameErrors = Validation.requiredStringField({
    form,
    field: 'inviteUserForm.names.0',
    message: 'Please enter a first name.',
  });
  const lastNameErrors = Validation.requiredStringField({
    form,
    field: 'inviteUserForm.names.1',
    message: 'Please enter a last name.',
  });
  const emailErrors = Validation.requiredStringField({
    form,
    field: 'inviteUserForm.email',
    message: 'Please enter a valid email address.',
  });
  const positionErrors = Validation.requiredStringField({
    form,
    field: 'inviteUserForm.position',
    message: 'Please enter a position.',
  });
  const roleErrors = Validation.requiredStringField({
    form,
    field: 'inviteUserForm.role',
    message: 'Please select a role.',
  });
  return {
    ...firstNameErrors,
    ...lastNameErrors,
    ...emailErrors,
    ...positionErrors,
    ...roleErrors,
  };
};

const validateFormForStep = ({stepKind, form}: {stepKind: string; form: any}): ValidationErrors => {
  switch (stepKind) {
    case InviteOfficeAppUserStep.USER_INFO:
      return validateUserInfo({form});
    default:
      return {};
  }
};

const validateStep = async ({
  stepKind,
  form,
}: {
  stepKind: string;
  form: any;
}): Promise<ValidationErrors> => {
  const errors = validateFormForStep({stepKind, form});
  await form.setTouched(_.mapValues(errors, () => true));
  await form.setErrors(errors);
  return errors;
};

const InviteUserForm = {
  new: _new,
  edit,
  toForm,
  toMutation,

  // Helpers
  validateStep,
};

export default InviteUserForm;
