// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive, useSearch} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import Line from '@shared/design/components/Line';
import SearchBar from '@shared/design/components/SearchBar';
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import TextTooltip from '@shared/design/components/TextTooltip';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import useUpdateSuperAdminOrganizationMutation from '@shared/modules/Organization/hooks/useUpdateSuperAdminOrganzationMutation';

const Container = Styled.View`
  height: 380px;
`;

const ContentContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SkeletonOption = () => (
  <Column>
    <Space height={8} />
    <Row>
      <Column style={{flex: 1}}>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Body} width={_.random(150, 250)} />
        <Space height={4} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Body} width={_.random(75, 150)} />
      </Column>
      <Space width={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={75} />
    </Row>
    <Space height={8} />
  </Column>
);

const SkeletonList = () => {
  const responsive = useResponsive();
  return (
    <ContentContainer>
      {responsive.desktop && <Space height={16} />}
      <SkeletonLoader height={36} isFullWidth />
      <Space height={16} />
      <FlatList
        listKey={'super-admin-organization-list-skeleton'}
        data={_.range(5)}
        renderItem={() => <SkeletonOption />}
        ItemSeparatorComponent={() => <Line color={colors.gray.border} />}
      />
    </ContentContainer>
  );
};

const Option = ({
  selectedOrganizationId,
  primaryOrganizationId,
  company,
  handlePress,
  isSubmitting,
}: {
  selectedOrganizationId?: string;
  primaryOrganizationId?: string;
  company: CompanyModel;
  handlePress: ({company}: {company: CompanyModel}) => void;
  isSubmitting: boolean;
}) => {
  const responsive = useResponsive();
  const companyOrganizationCount = company.organizations.length;
  const branchCount = companyOrganizationCount > 1 ? companyOrganizationCount - 1 : 0;
  return (
    <Column>
      <Space height={8} />
      <Row>
        <Column style={{flex: 1}}>
          <Text responsive={responsive} numberOfLines={1}>
            {company.name}
          </Text>
          <Space height={4} />
          <TextTooltip
            text={_.join(
              _.sortBy(company.organizations, ['name']).map((org) => org.slug),
              '\n',
            )}
            placement={'left'}
          >
            <Row>
              <Text
                responsive={responsive}
                style={{color: colors.gray.secondary}}
                numberOfLines={1}
              >
                {`${company.slug} - ${pluralize('Branch', _.toNumber(branchCount), true)}`}
              </Text>
            </Row>
          </TextTooltip>
        </Column>
        <Space width={16} />
        <QuaternaryButton
          textColor={colors.blue.interactive}
          text={'Select'}
          onPress={() => handlePress({company})}
          isDisabled={
            isSubmitting &&
            !!selectedOrganizationId &&
            selectedOrganizationId !== primaryOrganizationId
          }
          isSubmitting={selectedOrganizationId === primaryOrganizationId && isSubmitting}
        />
      </Row>
      <Space height={8} />
    </Column>
  );
};

const SuperAdminOrganizationListContent = ({
  companies,
  form,
  field,
  submitting,
  handleSubmit,
}: {
  companies: CompanyModel[];
  form: any;
  field: string;
  submitting: boolean;
  handleSubmit: () => void;
}) => {
  const responsive = useResponsive();
  const {
    query,
    results: filteredCompanies,
    setQuery,
  } = useSearch({
    initialQuery: '',
    items: _.sortBy(companies, ['slug']),
    options: {keys: ['slug', 'name', 'organizations.slug', 'organizations.name']},
    limit: 20,
  });

  return (
    <ContentContainer>
      {responsive.desktop && <Space height={16} />}
      <SearchBar
        autoFocus
        isResponsive
        isClearable
        placeholder={'Search for an organization by name'}
        style={{flex: 1}}
        defaultValue={query}
        onChangeText={(text: any) => {
          setQuery(text);
        }}
        onEnterPress={() => {
          const company = filteredCompanies[0];
          const primaryOrganization = _.find(company.organizations, {kind: OrganizationKind.MAIN});
          form.setFieldValue(field, primaryOrganization!.id);
          setImmediate(() => handleSubmit());
        }}
      />
      <Space height={16} />
      <FlatList
        listKey={'super-admin-organization-list'}
        data={filteredCompanies}
        keyExtractor={(company: CompanyModel) => company.id}
        renderItem={({item: company}: {item: CompanyModel}) => {
          const primaryOrganization = _.find(company.organizations, {kind: OrganizationKind.MAIN});
          return (
            <Option
              selectedOrganizationId={_.get(form.values, field)}
              primaryOrganizationId={_.toString(primaryOrganization!.id)}
              company={company}
              handlePress={() => {
                form.setFieldValue(field, primaryOrganization!.id);
                setImmediate(() => handleSubmit());
              }}
              isSubmitting={submitting}
            />
          );
        }}
        ItemSeparatorComponent={() => <Line color={colors.gray.border} />}
      />
    </ContentContainer>
  );
};

const SuperAdminOrganizationList = ({supermoveProductKind}: {supermoveProductKind: string}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(SuperAdminOrganizationList.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      supermoveProductKind,
    },
  });
  const {form, handleSubmit, submitting} = useUpdateSuperAdminOrganizationMutation({
    onSuccess: () => {
      window.location.href = '/';
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <Container responsive={responsive}>
      <Loading loading={loading} as={<SkeletonList />}>
        {() => (
          <SuperAdminOrganizationListContent
            companies={data.companies}
            form={form}
            field={'updateSuperAdminOrganizationForm.organizationId'}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SuperAdminOrganizationList.query = gql`
  query SuperAdminOrganizationList($supermoveProductKind: String!) {
    ${gql.query}
    companies: activeCompaniesForProduct(kind: $supermoveProductKind) {
      id
      slug
      name
      organizations {
        id
        name
        slug
        kind
      }
    }
  }
`;

export default SuperAdminOrganizationList;
