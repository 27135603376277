// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillRule} from '@supermove/models';
import {Currency, uuid, withFragment} from '@supermove/utils';

// App
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';

const toFormValues = (kind: string, values: string[]) => {
  if (!Array.isArray(values) || !_.get(values, '0')) {
    return [''];
  }

  const valueOne = _.get(values, '0', null);
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return [Currency.toForm(valueOne)];
    case BillRule.KINDS.CUSTOM:
    default:
      return [String(_.get(values, '0', ''))];
  }
};

const toMutationValues = (kind: string, values: string[]) => {
  const valueOne = _.get(values, '0', null);
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return [Currency.toMutation(valueOne)];
    case BillRule.KINDS.CUSTOM:
    default:
      return values;
  }
};

const getDisplayValues = ({kind, values}: {kind: string; values: string[]}) => {
  if (!Array.isArray(values)) {
    return '';
  }
  const valueOne = _.get(values, '0', null);
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
    case BillRule.KINDS.CUSTOM:
      return valueOne;
    case BillRule.KINDS.ADDITIONAL_HOURS:
    case BillRule.KINDS.MINIMUM_HOURS:
      return valueOne ? `${valueOne} hours` : '';
    default:
      return '';
  }
};

const getValueType = ({kind}: any) => {
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return BillRule.VALUE_TYPES.CURRENCY;
    case BillRule.KINDS.ADDITIONAL_HOURS:
    case BillRule.KINDS.MINIMUM_HOURS:
      return BillRule.VALUE_TYPES.HOURS;
    case BillRule.KINDS.CUSTOM:
    default:
      return BillRule.VALUE_TYPES.CUSTOM;
  }
};

const getFormValues = ({kind, values}: any) => {
  if (!Array.isArray(values)) {
    return [''];
  }

  const valueOne = _.get(values, '0', '');
  switch (kind) {
    case BillRule.KINDS.BEFORE_JOB_MINIMUM_PAYMENT:
    case BillRule.KINDS.MINIMUM_DOLLAR_AMOUNT:
    case BillRule.KINDS.NOT_TO_EXCEED_PRICE:
      return [Currency.toForm(Currency.convertToCents(valueOne))];
    case BillRule.KINDS.CUSTOM:
    default:
      return values;
  }
};

const validate = ({billRuleForm, formField}: any) => {
  const errors = {};
  if (!billRuleForm.kind) {
    _.set(errors, `${formField}.kind`, 'Please select a category');
  }
  if (!billRuleForm.name) {
    _.set(errors, `${formField}.name`, 'Please enter a name');
  }
  return errors;
};

const _new = ({kind}: any) => ({
  kind: kind || '',
  name: kind ? BillRule.getDefaultNameByKind(kind) : '',
  description: '',
  values: [''],
  isVisibleToCustomer: true,
  descriptionFormulaId: null,
  nameFormulaId: null,
  valueFormulaId: null,
  billRuleTypeId: null,
  billItemId: null,

  // Private
  isEditable: true,
});

const edit = withFragment(
  (billRule) => ({
    kind: (billRule as any).kind,
    name: (billRule as any).name,
    description: (billRule as any).description,
    values: (billRule as any).values,
    descriptionFormulaId: (billRule as any).descriptionFormulaId,
    nameFormulaId: (billRule as any).nameFormulaId,
    valueFormulaId: (billRule as any).valueFormulaId,
    isVisibleToCustomer: (billRule as any).isVisibleToCustomer,
    billRuleTypeId: (billRule as any).billRuleTypeId,
    billItemId: (billRule as any).billItemId,
  }),
  gql`
    fragment BillRuleForm_edit on BillRule {
      kind
      name
      description
      values
      descriptionFormulaId
      nameFormulaId
      valueFormulaId
      isVisibleToCustomer
      billRuleTypeId
      billItemId
    }
  `,
);

const getValuesFromBillRuleType = ({value, stringValue, kind}: any) => {
  if (kind === BillRuleKind.CUSTOM) {
    return stringValue ? [stringValue] : [''];
  }
  return value ? [value] : [''];
};

const editFromBillRuleType = withFragment(
  (billRuleType) => ({
    kind: (billRuleType as any).kind,
    name: (billRuleType as any).name,
    description: (billRuleType as any).description,
    values: getValuesFromBillRuleType({
      value: (billRuleType as any).value,
      stringValue: (billRuleType as any).stringValue,
      kind: (billRuleType as any).kind,
    }),
    descriptionFormulaId: (billRuleType as any).descriptionFormulaId,
    nameFormulaId: (billRuleType as any).nameFormulaId,
    valueFormulaId: (billRuleType as any).valueFormulaId,
    isVisibleToCustomer: (billRuleType as any).isVisibleToCustomer,
    billRuleTypeId: (billRuleType as any).id,
    billItemId: null,
  }),
  gql`
    fragment BillRuleForm_editFromBillRuleType on BillRuleType {
      id
      kind
      name
      description
      value
      stringValue
      descriptionFormulaId
      nameFormulaId
      valueFormulaId
      isVisibleToCustomer
    }
  `,
);

export type BillRuleFormType = {
  kind: string;
  name: string;
  description: string;
  values: string[];
  descriptionFormulaId?: string;
  nameFormulaId?: string;
  valueFormulaId?: string;
  isVisibleToCustomer: boolean;
  billRuleTypeId?: string;
  billItemId?: string;

  // Private
  isEditable: boolean;
  uuid: string;
};

const toForm = ({
  kind,
  name,
  description,
  values,
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
  billRuleTypeId,
  billItemId,
}: any): BillRuleFormType => ({
  kind,
  name,
  description,
  values: toFormValues(kind, values),
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
  billRuleTypeId,
  billItemId,

  // Private
  isEditable: false,
  uuid: uuid(), // Used as unique identifier for drag and drop
});

const toMutation = ({
  kind,
  name,
  description,
  values,
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
  billRuleTypeId,
  billItemId,
}: any) => ({
  kind,
  name,
  description,
  values: toMutationValues(kind, values),
  descriptionFormulaId,
  nameFormulaId,
  valueFormulaId,
  isVisibleToCustomer,
  billRuleTypeId,
  billItemId,
});

const BillRuleForm = {
  getDisplayValues,
  getFormValues,
  getValueType,
  validate,

  new: _new,
  edit,
  editFromBillRuleType,

  toForm,
  toMutation,
};

export default BillRuleForm;
