// Supermove
import {Form} from '@supermove/hooks';
import {Percent} from '@supermove/utils';

export type TipOptionFormToForm = {
  amount: string;
};
export type TipOptionFormType = Form<TipOptionFormToForm>;

const _new = ({amount = null} = {}) => ({
  amount,
});

const toForm = ({amount}: any) => ({
  amount: amount ? Percent.toForm(amount) : '',
});

const toMutation = ({amount}: any) => ({
  amount: amount ? Percent.toMutation(amount) : null,
});

const TipOptionForm = {
  new: _new,
  toForm,
  toMutation,
};

export default TipOptionForm;
