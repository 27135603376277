// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job, Organization, Project} from '@supermove/models';
import {Json, List, withFragment} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import JobForm from '@shared/modules/Job/forms/JobForm';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import CustomerForm from '@shared/modules/User/forms/CustomerForm';

const getIsAtMaxJobs = withFragment(
  (projectForm, {project}) => {
    return (
      (projectForm as any).jobForms.length >= Project.MAX_JOB_COUNT &&
      !project.organization.features.isEnabledUnlimitedProjectJobs
    );
  },
  gql`
    fragment ProjectForm_getIsAtMaxJobs on Project {
      id
      organization {
        id
        features {
          isEnabledUnlimitedProjectJobs: isEnabled(feature: "UNLIMITED_PROJECT_JOBS")
        }
      }
    }
  `,
);

const copyFromJob = withFragment(
  (job) => {
    const jobForm = JobForm.copy(job, {isDateReset: true});

    return {
      projectId: undefined,
      organizationId: (job as any).organizationId,
      projectTypeId: (job as any).project.projectTypeId,
      creatorId: (job as any).creatorId,
      bookedById: (job as any).bookedById,
      coordinatedById: (job as any).project.organization.features
        .isEnabledProjectCoordinatorRequired
        ? (job as any).project.coordinatedById
        : undefined,
      additionalSalespersonIds: (job as any).project.additionalSalespersonIds,
      customerForm: jobForm.customerForm,
      clientForm: ClientForm.edit((job as any).project.client),
      billingClientForm: ClientForm.edit(
        (job as any).project.billingClient
          ? (job as any).project.billingClient
          : (job as any).project.client,
      ),
      jobForms: [jobForm],
      valueForms: [],
      name: '',
      identifier: '',
      description: '',
      referralSource: (job as any).project.referralSource,
      referralDetails: (job as any).project.referralDetails,
      isTest: (job as any).project.isTest,
      size: (job as any).project.size,
      isSameBillingClient: determineIsSameBillingClient((job as any).project),
      // Private Fields
      isNameVisible: !!(job as any).project.name,
      isClientSearchEnabled: false,
      primaryLaborSourceOrganizationId: (job as any).project.owningOrganization.id,
    };
  },
  gql`
    ${ClientForm.edit.fragment}
    ${JobForm.copy.fragment}

    fragment ProjectForm_copyFromJob on Job {
      id
      organizationId
      creatorId
      bookedById
      kind
      name
      project {
        id
        coordinatedById
        projectTypeId
        additionalSalespersonIds
        referralSource
        referralDetails
        isTest
        size
        client {
          id
          ...ClientForm_edit
        }
        billingClient {
          id
          ...ClientForm_edit
        }
        organization {
          id
          features {
            isEnabledProjectCoordinatorRequired: isEnabled(feature: "PROJECT_COORDINATOR_REQUIRED")
          }
        }
        owningOrganization {
          id
        }
      }
      ...JobForm_copy
    }
  `,
);

const copy = withFragment(
  (project) => {
    return {
      projectId: null,
      organizationId: (project as any).organizationId,
      projectTypeId: (project as any).projectTypeId,
      creatorId: (project as any).creatorId,
      bookedById: (project as any).bookedById,
      coordinatedById: (project as any).organization.features.isEnabledProjectCoordinatorRequired
        ? (project as any).coordinatedById
        : null,
      additionalSalespersonIds: (project as any).additionalSalespersonIds,
      customerForm: null,
      clientForm: (project as any).client
        ? ClientForm.edit((project as any).client)
        : ClientForm.newFromProject(project),
      billingClientForm: (project as any).billingClient
        ? ClientForm.edit((project as any).billingClient)
        : ClientForm.newFromProject(project),
      jobForms: (project as any).activeJobs.map((job: any) => JobForm.copy(job)),
      valueForms: [],
      name: (project as any).name,
      identifier: '',
      description: (project as any).description,
      referralSource: (project as any).referralSource,
      referralDetails: (project as any).referralDetails,
      isTest: (project as any).isTest,
      size: (project as any).size,
      isSameBillingClient: determineIsSameBillingClient(project),
      // Private Fields
      isNameVisible: !!(project as any).name,
      isClientSearchEnabled: false,
      primaryLaborSourceOrganizationId: (project as any).owningOrganization.id,
    };
  },
  gql`
    ${ClientForm.edit.fragment}
    ${ClientForm.newFromProject.fragment}
    ${CustomerForm.edit.fragment}
    ${JobForm.copy.fragment}

    fragment ProjectForm_copy on Project {
      id
      organizationId
      projectTypeId
      creatorId
      bookedById
      coordinatedById
      additionalSalespersonIds
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      organization {
        id
        features {
          isEnabledProjectCoordinatorRequired: isEnabled(feature: "PROJECT_COORDINATOR_REQUIRED")
        }
      }
      activeJobs {
        id
        ...JobForm_copy
      }
      name
      description
      customer {
        id
        ...CustomerForm_edit
      }
      referralSource
      referralDetails
      isTest
      size
      owningOrganization {
        id
      }
      ...ClientForm_newFromProject
    }
  `,
);

// Note that we query for only active jobs, not all jobs
const editV2 = withFragment(
  (project, {isClientSearchEnabled} = {}) => ({
    projectId: (project as any).id,
    organizationId: (project as any).organizationId,
    projectTypeId: (project as any).projectTypeId,
    creatorId: (project as any).creatorId,
    bookedById: (project as any).bookedById,
    coordinatedById: (project as any).coordinatedById,
    additionalSalespersonIds: (project as any).additionalSalespersonIds,
    clientForm: (project as any).client
      ? ClientForm.edit((project as any).client)
      : ClientForm.newFromProject(project),
    billingClientForm: (project as any).billingClient
      ? ClientForm.edit((project as any).billingClient)
      : ClientForm.newFromProject(project),
    jobForms: Job.sortJobsBySequence((project as any).activeJobs).map((job) => JobForm.editV2(job)),
    valueForms: Organization.makeProjectValueFormsFromProjectValues({project}),
    name: (project as any).name,
    identifier: (project as any).identifier,
    description: (project as any).description,
    referralSource: (project as any).referralSource,
    referralDetails: (project as any).referralDetails,
    isTest: (project as any).isTest,
    size: (project as any).size,
    isSameBillingClient: determineIsSameBillingClient(project),
    // Private Fields
    isNameVisible: !!(project as any).name,
    shouldUseCustomerForJobName: false,
    isClientSearchEnabled,
    primaryLaborSourceOrganizationId: (project as any).owningOrganization.id,
  }),
  gql`
    ${ClientForm.newFromProject.fragment}
    ${ClientForm.edit.fragment}
    ${JobForm.editV2.fragment}
    ${Job.sortJobsBySequence.fragment}
    ${Organization.makeProjectValueFormsFromProjectValues.fragment}
    ${ValueForm.edit.fragment}

    fragment ProjectForm_editV2 on Project {
      id
      organizationId
      creatorId
      bookedById
      coordinatedById
      projectTypeId
      additionalSalespersonIds
      name
      identifier
      description
      referralSource
      referralDetails
      isTest
      size
      activeJobs {
        id
        isCancelled
        ...JobForm_editV2
        ...Job_sortJobsBySequence
      }
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      values {
        id
        ...ValueForm_edit
      }
      owningOrganization {
        id
      }
      ...ClientForm_newFromProject
      ...Organization_makeProjectValueFormsFromProjectValues
    }
  `,
);

const editV2WithNewJob = withFragment<any, any, any>(
  (project, {creatorId}) => {
    const projectForm = ProjectForm.editV2(project);
    const lastJob = _.last(project.activeJobs) || project.lastJob;
    const newJobForm = JobForm.newFromJob(lastJob, {kind: null, creatorId});
    const overrideJobForm = {
      ...newJobForm,
      shouldUseCustomerForJobName: true,
      name: '',
    };

    return {
      ...projectForm,
      jobForms: [
        ...projectForm.jobForms,
        ...List.insertIf(!getIsAtMaxJobs(projectForm, {project}), overrideJobForm),
      ],
    };
  },
  gql`
    ${editV2.fragment}
    ${JobForm.newFromJob.fragment}
    ${getIsAtMaxJobs.fragment}

    fragment ProjectForm_editV2WithNewJob on Project {
      id
      activeJobs {
        id
        ...JobForm_newFromJob
      }
      lastJob {
        id
        ...JobForm_newFromJob
      }
      ...ProjectForm_editV2
      ...ProjectForm_getIsAtMaxJobs
    }
  `,
);

// Deprecating this in favor of editV2
const edit = withFragment(
  (project) => ({
    projectId: (project as any).id,
    organizationId: (project as any).organizationId,
    projectTypeId: (project as any).projectTypeId,
    creatorId: (project as any).creatorId,
    bookedById: (project as any).bookedById,
    coordinatedById: (project as any).coordinatedById,
    additionalSalespersonIds: (project as any).additionalSalespersonIds,
    customerForm: CustomerForm.edit((project as any).customer),
    jobForms: (project as any).jobs.map((job: any) => JobForm.edit(job)),
    valueForms: (project as any).values.map((value: any) => ValueForm.edit(value)),
    name: (project as any).name,
    identifier: (project as any).identifier,
    description: (project as any).description,
    isTest: (project as any).isTest,
    size: (project as any).size,
    // Private Fields
    isNameVisible: !!(project as any).name,
    isClientSearchEnabled: false,
    primaryLaborSourceOrganizationId: (project as any).owningOrganization.id,
  }),
  gql`
    ${CustomerForm.edit.fragment}
    ${JobForm.edit.fragment}
    ${ValueForm.edit.fragment}

    fragment ProjectForm_edit on Project {
      id
      organizationId
      creatorId
      bookedById
      coordinatedById
      projectTypeId
      additionalSalespersonIds
      name
      identifier
      description
      isTest
      size
      customer {
        id
        ...CustomerForm_edit
      }
      jobs {
        id
        ...JobForm_edit
      }
      values {
        id
        ...ValueForm_edit
      }
      owningOrganization {
        id
      }
    }
  `,
);

// This is used to create the REQUEST job types from the Customer App with virtual walkthrough.
const addJobToProject = withFragment<any, any, any>(
  (project, {kind, creatorId}) => {
    const projectForm = ProjectForm.edit(project);
    const lastJob = _.last(project.jobs);
    const newJobForm = JobForm.newFromJob(lastJob, {kind, creatorId});

    return {
      ...projectForm,
      jobForms: [
        ...projectForm.jobForms,
        {...newJobForm, jobTypeId: project.projectType.requestJobType.id},
      ],
    };
  },
  gql`
    ${edit.fragment}
    ${JobForm.newFromJob.fragment}

    fragment ProjectForm_addJobToProject on Project {
      id
      jobs {
        id
        ...JobForm_newFromJob
      }
      projectType {
        id
        requestJobType {
          id
        }
      }
      ...ProjectForm_edit
    }
  `,
);

// When the organization/branch is changed on a project, specific form values are reset
const resetWithNewOrganization = withFragment(
  (projectForm, {organizationForCreateProjectId, organizationForLaborSourceId, organization}) => {
    return {
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      ...projectForm,
      organizationId: organizationForCreateProjectId,
      primaryLaborSourceOrganizationId: organizationForLaborSourceId,
      projectTypeId: null,
      valueForms: [],
      bookedById: null,
      coordinatedById: null,
      additionalSalespersonIds: [],
      jobForms: (projectForm as any).jobForms.map((jobForm: any) => ({
        ...jobForm,
        organizationId: organizationForCreateProjectId,
        jobTypeId: null,
        date: '',
        startDate: '',
        endDate: '',
        valueForms: [],
        referralSource: '',
        referralDetails: '',
        warehouseLocationForm:
          organization.features.isEnabledCreateStorageMultipleWarehouse &&
          organization.defaultWarehouse
            ? LocationForm.edit(organization.defaultWarehouse.location, {
                warehouseName: organization.defaultWarehouse.name,
              })
            : null,
      })),
    };
  },
  gql`
    ${LocationForm.edit.fragment}

    fragment ProjectForm_resetWithNewOrganization on Organization {
      id
      defaultWarehouse {
        id
        name
        location {
          id
          ...LocationForm_edit
        }
      }
      features {
        isEnabledCreateStorageMultipleWarehouse: isEnabled(
          feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
        )
      }
    }
  `,
);

const _new = withFragment(
  (
    organization,
    {
      creatorId,
      bookedById,
      organizationId,
      primaryLaborSourceOrganizationId,
      sourceId,
      additionalItems,
      clientForm,
    },
  ) => {
    return {
      projectId: null,
      organizationId,
      sourceId,
      projectTypeId: null,
      creatorId,
      bookedById: clientForm.salespersonId || bookedById,
      coordinatedById: clientForm.coordinatorId || null,
      additionalSalespersonIds: [],
      jobForms: [
        JobForm.new({
          creatorId,
          bookedById,
          organizationId,
          startTime1: (organization as any).jobStartTime1Default,
          startTime2: (organization as any).jobStartTime2Default,
          additionalItems,
          referralSource: clientForm.referralSource,
          referralDetails: clientForm.referralDetails,
          warehouseLocationForm:
            (organization as any).features.isEnabledCreateStorageMultipleWarehouse &&
            (organization as any).defaultWarehouse
              ? LocationForm.edit((organization as any).defaultWarehouse.location, {
                  warehouseName: (organization as any).defaultWarehouse.name,
                })
              : null,
          isTest: false,
        }),
      ],
      valueForms: [],
      name: '',
      identifier: '',
      description: '',
      referralSource: clientForm.referralSource,
      referralDetails: clientForm.referralDetails,
      isTest: false,
      size: '',
      clientForm,
      billingClientForm: clientForm,
      // Private Fields
      isNameVisible: false,
      isClientSearchEnabled: true,
      isSameBillingClient: true,
      primaryLaborSourceOrganizationId: primaryLaborSourceOrganizationId || null,
    };
  },
  gql`
    ${LocationForm.edit.fragment}

    fragment ProjectForm_new on Organization {
      id
      jobStartTime1Default
      jobStartTime2Default
      defaultWarehouse {
        id
        name
        location {
          id
          ...LocationForm_edit
        }
      }
      features {
        isEnabledCreateStorageMultipleWarehouse: isEnabled(
          feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
        )
      }
    }
  `,
);

const newFromJobRequest = withFragment(
  (jobRequest, {viewerId, sourceId}) => {
    return {
      projectId: undefined,
      organizationId: (jobRequest as any).organization.id,
      sourceId,
      projectTypeId: undefined,
      creatorId: viewerId,
      bookedById:
        (jobRequest as any).bookedById ||
        ((jobRequest as any).organization.features.isEnabledNoAutoAssignBookedByToViewer
          ? undefined
          : viewerId),
      coordinatedById: undefined,
      additionalSalespersonIds: [],
      clientForm: ClientForm.newFromJobRequest(jobRequest, {viewerId}),
      billingClientForm: ClientForm.newFromJobRequest(jobRequest, {viewerId}),
      jobForms: [JobForm.newFromJobRequest(jobRequest, {viewerId})],
      valueForms: [],
      name: '',
      identifier: '',
      description: '',
      referralSource: (jobRequest as any).referralSource,
      referralDetails: (jobRequest as any).referralDetails,
      isTest: false,
      size: (jobRequest as any).moveSize,
      // Private Fields
      isNameVisible: false,
      isClientSearchEnabled: true,
      isSameBillingClient: true,
      primaryLaborSourceOrganizationId: (jobRequest as any).organization.id,
    };
  },
  gql`
    ${ClientForm.newFromJobRequest.fragment}
    ${JobForm.newFromJobRequest.fragment}

    fragment ProjectForm_newFromJobRequest on JobRequest {
      id
      bookedById
      moveSize
      referralSource
      referralDetails
      organization {
        id
        features {
          isEnabledNoAutoAssignBookedByToViewer: isEnabled(
            feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER"
          )
        }
      }
      ...ClientForm_newFromJobRequest
      ...JobForm_newFromJobRequest
    }
  `,
);

const newFromStorageProject = withFragment(
  (project, {collectionIds, containerIds, viewerId} = {}) => ({
    projectId: null,
    sourceProjectId: (project as any).id,
    creatorId: viewerId,
    bookedById: (project as any).organization.features.isEnabledNoAutoAssignBookedByToViewer
      ? undefined
      : viewerId,
    projectTypeId: null,
    organizationId: (project as any).organization.id,
    containerIds,
    name: '',
    description: '',
    size: '',
    identifier: '',
    officeNotes: '',
    warehouseNotes: '',
    referralSource: (project as any).referralSource,
    referralDetails: (project as any).referralDetails,
    isTest: (project as any).isTest,
    clientForm: ClientForm.edit((project as any).client),
    billingClientForm: (project as any).billingClient
      ? ClientForm.edit((project as any).billingClient)
      : ClientForm.edit((project as any).client),
    valueForms: Organization.makeProjectValueFormsFromOrganizationVariables(
      (project as any).organization,
    ),
    jobForms: [
      JobForm.new({
        creatorId: viewerId,
        bookedById:
          (project as any).bookedById ||
          ((project as any).organization.features.isEnabledNoAutoAssignBookedByToViewer
            ? undefined
            : viewerId),
        organizationId: (project as any).organization.id,
        startTime1: (project as any).organization.jobStartTime1Default,
        startTime2: (project as any).organization.jobStartTime2Default,
        additionalItems: Json.toString(
          Organization.getJobFormAdditionalItemsDefaultValues(
            (project as any).organization.organizationForCreateProject,
          ),
        ),
        referralSource: (project as any).referralSource,
        referralDetails: (project as any).referralDetails,
        isTest: (project as any).isTest,
      }),
    ],
    // DEPRECATED(cooper): We don't pass collectionIds anymore, use containerIds instead
    collectionIds,
    // Private Fields
    isProjectNameVisible: false,
    isClientSearchEnabled: false,
    isSameBillingClient: determineIsSameBillingClient(project),
    primaryLaborSourceOrganizationId: (project as any).owningOrganization.id,
  }),
  gql`
    ${ClientForm.edit.fragment}
    ${Organization.getJobFormAdditionalItemsDefaultValues.fragment}
    ${Organization.makeProjectValueFormsFromOrganizationVariables.fragment}
    fragment ProjectForm_newFromStorageProject on Project {
      id
      bookedById
      isTest
      referralSource
      referralDetails
      client {
        id
        ...ClientForm_edit
      }
      billingClient {
        id
        ...ClientForm_edit
      }
      organization {
        id
        jobStartTime1Default
        jobStartTime2Default
        features {
          isEnabledNoAutoAssignBookedByToViewer: isEnabled(
            feature: "NO_AUTO_ASSIGN_BOOKED_BY_TO_VIEWER"
          )
        }
        organizationForCreateProject {
          id
          ...Organization_getJobFormAdditionalItemsDefaultValues
        }
        ...Organization_makeProjectValueFormsFromOrganizationVariables
      }
      owningOrganization {
        id
      }
    }
  `,
);

const toForm = ({
  bookedById,
  creatorId,
  organizationId,
  primaryLaborSourceOrganizationId,
  projectId,
  sourceProjectId,
  projectTypeId,
  sourceId,
  coordinatedById,
  additionalSalespersonIds,
  containerIds,
  customerForm,
  clientForm,
  billingClientForm,
  jobForms,
  valueForms,
  name,
  identifier,
  description,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  size,
  isSameBillingClient,

  // DEPRECATED(cooper): We don't pass collectionIds anymore, use containerIds instead
  collectionIds,

  // Private Fields
  isNameVisible,

  shouldUseCustomerForJobName,
  isClientSearchEnabled,
}: any) => ({
  projectId,
  sourceProjectId,
  organizationId,
  primaryLaborSourceOrganizationId,
  projectTypeId,
  creatorId,
  bookedById,
  sourceId,
  coordinatedById,
  additionalSalespersonIds,
  containerIds,
  customerForm: customerForm ? CustomerForm.toForm(customerForm) : null,
  clientForm: clientForm ? ClientForm.toForm(clientForm) : null,
  billingClientForm: billingClientForm
    ? ClientForm.toForm(billingClientForm)
    : clientForm
      ? ClientForm.toForm(clientForm)
      : null,
  jobForms: jobForms.map((jobForm: any) => JobForm.toForm(jobForm)),
  valueForms: valueForms.map((valueForm: any) => ValueForm.toForm(valueForm)),
  name,
  identifier,
  description,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  size,
  isSameBillingClient,

  // DEPRECATED(cooper): We don't pass collectionIds anymore, use containerIds instead
  collectionIds,

  // Private Fields
  isNameVisible,
  shouldUseCustomerForJobName,
  isClientSearchEnabled,
});

const toMutation = ({
  projectId,
  sourceProjectId,
  organizationId,
  primaryLaborSourceOrganizationId,
  projectTypeId,
  sourceId,
  creatorId,
  bookedById,
  coordinatedById,
  additionalSalespersonIds,
  containerIds,
  customerForm,
  clientForm,
  billingClientForm,
  jobForms,
  valueForms,
  name,
  identifier,
  description,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  size,
  isSameBillingClient,

  // DEPRECATED(cooper): We don't pass collectionIds anymore, use containerIds instead
  collectionIds,
}: any) => ({
  projectId,
  sourceProjectId,
  organizationId,
  projectTypeId,
  sourceId,
  creatorId,
  bookedById: bookedById === '' ? null : bookedById,
  coordinatedById: coordinatedById === '' ? null : coordinatedById,
  additionalSalespersonIds,
  containerIds,
  customerForm: customerForm ? CustomerForm.toMutation(customerForm) : null,
  clientForm: clientForm ? ClientForm.toMutation(clientForm) : null,
  billingClientForm: billingClientForm
    ? ClientForm.toMutation(billingClientForm)
    : clientForm
      ? ClientForm.toMutation(clientForm)
      : null,
  jobForms: jobForms.map((jobForm: any) => {
    // NOTE(mark): This is a hack. Since the customerForm is now on the projectForm, we pass it down
    // to the jobForm so that we can form the job.name field from the customer name.
    return JobForm.toMutation({
      ...jobForm,
      customerForm,
      clientForm,
      primaryLaborSourceOrganizationId,
    });
  }),
  valueForms: valueForms.map((valueForm: any) => ValueForm.toMutation(valueForm)),
  name,
  identifier,
  description,
  officeNotes,
  warehouseNotes,
  referralSource,
  referralDetails,
  isTest,
  size,
  isSameBillingClient,

  // DEPRECATED(cooper): We don't pass collectionIds anymore, use containerIds instead
  collectionIds,
});

const determineIsSameBillingClient = (project: any) => {
  // Checks if billing client and client id matches, return true
  // Otherwise it'll return true if billing client and client both don't exist
  // Else false
  return project.billingClient && project.client
    ? project.billingClient.id === project.client.id
    : !project.billingClient && !project.client;
};

const ProjectForm = {
  copyFromJob,
  copy,
  new: _new,
  newFromJobRequest,
  newFromStorageProject,
  edit,
  editV2,
  editV2WithNewJob,
  addJobToProject,
  resetWithNewOrganization,

  toForm,
  toMutation,

  // Helpers
  getIsAtMaxJobs,
};

export default ProjectForm;
